<script>
  // import Code from "../../../components/Code.svelte";
  import Table from "../_components/Table.svelte";
  import { _ } from "../../../i18n";
  import { TabPane, TabContent, Nav, NavItem, NavLink } from "sveltestrap";
  import { Container, Row, Col } from "sveltestrap";
  import QueryForm from "../_components/QueryForm.svelte";
  import { onMount } from "svelte";

  let Prism;
  onMount(async () => {
    const prismModule = await import("svelte-prismjs");
    await import("prismjs/components/prism-json.js");
    Prism = prismModule.default;
  });

  //import hljs from "highlight.js";
  //import json from "highlight.js/lib/languages/json";

  export let scoped;
  scoped;
  export let scopedSync;
  scopedSync;

  //hljs.registerLanguage("json", json);

  let results = {};
  let rows = [];
  let highlighted = "";

  function handleResponse(event) {
    //console.log("Response: ", event.detail);
    results = event.detail;
    rows = results.records;
    highlighted = JSON.stringify(results, undefined, 1); //hljs.highlight("json", JSON.stringify(results, undefined, 1)).value;
  }

  let cols = {
    shortname: {
      path: "shortname",
      title: $_("shortname"),
      type: "string",
    },
    displayname: {
      path: "attributes.displayname",
      title: $_("displayname"),
      type: "string",
    },
    tags: {
      path: "attributes.tags",
      title: $_("tags"),
      type: "string",
    },
    payload: {
      path: "attributes.payload.embedded",
      title: $_("payload"),
      type: "string",
    },
    is_active: {
      path: "attributes.is_active",
      title: $_("is_active"),
      type: "string",
    },
  };

  let myactive = "original";
</script>

<Container fluid="{true}" class="h-100">
  <Row class="h-100" noGutters>
    <Col sm="2" class="h-100">
      <QueryForm on:response="{handleResponse}" />
    </Col>
    <Col sm="10" class="h-100 d-flex flex-column">
      <Nav tabs>
        <NavItem
          ><NavLink href="#" on:click="{() => (myactive = 'original')}" active="{myactive == 'original'}"
            >{$_("raw")}</NavLink
          ></NavItem>
        <NavItem
          ><NavLink href="#" on:click="{() => (myactive = 'tabular')}" active="{myactive == 'tabular'}"
            >{$_("tabular")}</NavLink
          ></NavItem>
      </Nav>
      <div class="flex-grow-0 overflow-y-auto" style="min-height: 0;">
        <TabContent class="h-100" activeTab="{myactive}">
          <TabPane class="h-100" tabId="tabular">
            <Table cols="{cols}" rows="{rows}" />
          </TabPane>
          <TabPane class="h-100" tabId="original">
            <!--pre
              class="h-100 mx-2 hljs mb-0"
              style="direction: ltr; text-align: left; white-space: pre-wrap; overflow-y: scroll; overflow-x: hidden;">
                <code>{@html highlighted}</code> 
              </pre-->
            <svelte:component
              this="{Prism}"
              classes="h-100 mx-2 m-0 overflow-auto"
              style="direction: ltr; font-size: 0.8rem;">
              {highlighted}
            </svelte:component>
          </TabPane>
        </TabContent>
      </div>
    </Col>
  </Row>
</Container>

<style global>
  /*@import "prismjs/plugins/line-numbers/prism-line-numbers.css";
  @import "prismjs/plugins/command-line/prism-command-line.css";
  @import "prismjs/plugins/line-highlight/prism-line-highlight.css";*/
  /**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
  :global(code[class*="language-"]),
:global(pre[class*="language-"]) {
	color: black;
	background: none;
	text-shadow: 0 1px white;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
  :global(pre[class*="language-"]::-moz-selection), :global(pre[class*="language-"]) :global(::-moz-selection), :global(code[class*="language-"]::-moz-selection), :global(code[class*="language-"]) :global(::-moz-selection) {
	text-shadow: none;
	background: #b3d4fc;
}
  :global(pre[class*="language-"]::selection), :global(pre[class*="language-"]) :global(::selection), :global(code[class*="language-"]::selection), :global(code[class*="language-"]) :global(::selection) {
	text-shadow: none;
	background: #b3d4fc;
}
  @media print {
	:global(code[class*="language-"]),
	:global(pre[class*="language-"]) {
		text-shadow: none;
	}
}
  /* Code blocks */
  :global(pre[class*="language-"]) {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
}
  :global(:not(pre)) > :global(code[class*="language-"]),
:global(pre[class*="language-"]) {
	background: #f5f2f0;
}
  /* Inline code */
  :global(:not(pre)) > :global(code[class*="language-"]) {
	padding: .1em;
	border-radius: .3em;
	white-space: normal;
}
  :global(.token.comment),
:global(.token.prolog),
:global(.token.doctype),
:global(.token.cdata) {
	color: slategray;
}
  :global(.token.punctuation) {
	color: #999;
}
  :global(.token.namespace) {
	opacity: .7;
}
  :global(.token.property),
:global(.token.tag),
:global(.token.boolean),
:global(.token.number),
:global(.token.constant),
:global(.token.symbol),
:global(.token.deleted) {
	color: #905;
}
  :global(.token.selector),
:global(.token.attr-name),
:global(.token.string),
:global(.token.char),
:global(.token.builtin),
:global(.token.inserted) {
	color: #690;
}
  :global(.token.operator),
:global(.token.entity),
:global(.token.url),
:global(.language-css) :global(.token.string),
:global(.style) :global(.token.string) {
	color: #9a6e3a;
	/* This background color was intended by the author of this theme. */
	background: hsla(0, 0%, 100%, .5);
}
  :global(.token.atrule),
:global(.token.attr-value),
:global(.token.keyword) {
	color: #07a;
}
  :global(.token.function),
:global(.token.class-name) {
	color: #DD4A68;
}
  :global(.token.regex),
:global(.token.important),
:global(.token.variable) {
	color: #e90;
}
  :global(.token.important),
:global(.token.bold) {
	font-weight: bold;
}
  :global(.token.italic) {
	font-style: italic;
}
  :global(.token.entity) {
	cursor: help;
}
  /**
 * prism.js Coy theme for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/tshedor/workshop-wp-theme (Example: http://workshop.kansan.com/category/sessions/basics or http://workshop.timshedor.com/category/sessions/basics);
 * @author Tim  Shedor
 */
  :global(code[class*="language-"]),
:global(pre[class*="language-"]) {
	color: black;
	background: none;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
  /* Code blocks */
  :global(pre[class*="language-"]) {
	position: relative;
	margin: .5em 0;
	overflow: visible;
	padding: 0;
}
  :global(pre[class*="language-"])>:global(code) {
	position: relative;
	border-left: 10px solid #358ccb;
	box-shadow: -1px 0px 0px 0px #358ccb, 0px 0px 0px 1px #dfdfdf;
	background-color: #fdfdfd;
	background-image: linear-gradient(transparent 50%, rgba(69, 142, 209, 0.04) 50%);
	background-size: 3em 3em;
	background-origin: content-box;
	background-attachment: local;
}
  :global(code[class*="language-"]) {
	max-height: inherit;
	height: inherit;
	padding: 0 1em;
	display: block;
	overflow: auto;
}
  /* Margin bottom to accommodate shadow */
  :global(:not(pre)) > :global(code[class*="language-"]),
:global(pre[class*="language-"]) {
	background-color: #fdfdfd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin-bottom: 1em;
}
  /* Inline code */
  :global(:not(pre)) > :global(code[class*="language-"]) {
	position: relative;
	padding: .2em;
	border-radius: 0.3em;
	color: #c92c2c;
	border: 1px solid rgba(0, 0, 0, 0.1);
	display: inline;
	white-space: normal;
}
  :global(pre[class*="language-"]:before),
:global(pre[class*="language-"]:after) {
	content: '';
	z-index: -2;
	display: block;
	position: absolute;
	bottom: 0.75em;
	left: 0.18em;
	width: 40%;
	height: 20%;
	max-height: 13em;
	box-shadow: 0px 13px 8px #979797;
	-webkit-transform: rotate(-2deg);
	-moz-transform: rotate(-2deg);
	-ms-transform: rotate(-2deg);
	-o-transform: rotate(-2deg);
	transform: rotate(-2deg);
}
  :global(pre[class*="language-"]:after) {
	right: 0.75em;
	left: auto;
	-webkit-transform: rotate(2deg);
	-moz-transform: rotate(2deg);
	-ms-transform: rotate(2deg);
	-o-transform: rotate(2deg);
	transform: rotate(2deg);
}
  :global(.token.comment),
:global(.token.block-comment),
:global(.token.prolog),
:global(.token.doctype),
:global(.token.cdata) {
	color: #7D8B99;
}
  :global(.token.punctuation) {
	color: #5F6364;
}
  :global(.token.property),
:global(.token.tag),
:global(.token.boolean),
:global(.token.number),
:global(.token.function-name),
:global(.token.constant),
:global(.token.symbol),
:global(.token.deleted) {
	color: #c92c2c;
}
  :global(.token.selector),
:global(.token.attr-name),
:global(.token.string),
:global(.token.char),
:global(.token.function),
:global(.token.builtin),
:global(.token.inserted) {
	color: #2f9c0a;
}
  :global(.token.operator),
:global(.token.entity),
:global(.token.url),
:global(.token.variable) {
	color: #a67f59;
	background: rgba(255, 255, 255, 0.5);
}
  :global(.token.atrule),
:global(.token.attr-value),
:global(.token.keyword),
:global(.token.class-name) {
	color: #1990b8;
}
  :global(.token.regex),
:global(.token.important) {
	color: #e90;
}
  :global(.language-css) :global(.token.string),
:global(.style) :global(.token.string) {
	color: #a67f59;
	background: rgba(255, 255, 255, 0.5);
}
  :global(.token.important) {
	font-weight: normal;
}
  :global(.token.bold) {
	font-weight: bold;
}
  :global(.token.italic) {
	font-style: italic;
}
  :global(.token.entity) {
	cursor: help;
}
  :global(.token.namespace) {
	opacity: .7;
}
  @media screen and (max-width: 767px) {
	:global(pre[class*="language-"]:before),
	:global(pre[class*="language-"]:after) {
		bottom: 14px;
		box-shadow: none;
	}

}
  /* Plugin styles: Line Numbers */
  :global(pre[class*="language-"].line-numbers.line-numbers) {
	padding-left: 0;
}
  :global(pre[class*="language-"].line-numbers.line-numbers) :global(code) {
	padding-left: 3.8em;
}
  :global(pre[class*="language-"].line-numbers.line-numbers) :global(.line-numbers-rows) {
	left: 0;
}
  /* Plugin styles: Line Highlight */
  :global(pre[class*="language-"][data-line]) {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
}
  :global(pre[data-line]) :global(code) {
	position: relative;
	padding-left: 4em;
}
  :global(pre) :global(.line-highlight) {
	margin-top: 0;
}
  /*code { white-space: pre-wrap; }*/
  :global(pre[class*="language-"]),
  :global(code[class*="language-"]) {
    white-space: pre-wrap;
  }
</style>
