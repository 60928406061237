<script>
  import { setupI18n, dir } from "./i18n";
  import { Router } from "@roxi/routify";
  import { routes } from "../.routify/routes";

  setupI18n(); 
  $: { 
    try {
		document.dir = $dir; 
      //let head = document.getElementsByTagName('HEAD')[0];
		
      //let bootstrap = document.getElementById("bootstrap");
      // if(bootstrap) { head.removeChild(old_bootstrap); }
      //let bootstrap = document.createElement('link');
      //bootstrap.id = "bootstrap";
      //bootstrap.rel = 'stylesheet';
      //bootstrap.type = 'text/css';

      //let fonts = document.getElementById("fonts");
    // if(old_fonts) { head.removeChild(old_fonts); }
    // let fonts = document.createElement('link');
      //fonts.id = "fonts";
      //fonts.rel = 'stylesheet';
      //fonts.type = 'text/css';

		if($dir == "rtl") {
      document.head.children["bootstap"].href="/bootstrap.rtl.min.css"
      document.head.children["fonts"].href="/fonts/rtl_fonts.css";
      //bootstrap.href =
      //document.getElementById("bootstrap").href = '/bootstrap.rtl.min.css';
      // fonts.href=
      //document.getElementById("fonts").href = '/fonts/rtl_fonts.css';
		} else {
      document.head.children["bootstap"].href="/bootstrap.min.css"
      document.head.children["fonts"].href="/fonts/fonts.css";
      //document.getElementById("bootstrap").href = '/bootstrap.min.css';
      //document.getElementById("fonts").href = '/fonts/fonts.css';
		}

      // head.appendChild(bootstrap);
      // head.appendChild(fonts);
    } catch (error) {
      console.log("Error in App:", error)
    }
	}
</script>

<style  global>:global(html), :global(body) {
	position: relative;
	width: 100%;
	height: 100%;
}

:global(body) {
	color: #333;
	margin: 0;
	padding: 4px;
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  scrollbar-color: darkgrey aliceblue;
}

:global(*) {
    scrollbar-width: thin;
}

:global(#routify-app) {
	height: 100%;
	width: 100%;
}

:global(a) {
	color: rgb(0,100,200);
	text-decoration: none;
}

:global(a:hover) {
	text-decoration: none;
}

:global(a:visited) {
	color: rgb(0,80,160);
}

/*
label {
	display: block;
}

input, button, select, textarea {
	font-family: inherit;
	font-size: inherit;
	padding: 0.4em;
	margin: 0 0 0.5em 0;
	box-sizing: border-box;
	border: 1px solid #ccc;
	border-radius: 2px;
}

input:disabled {
	color: #ccc;
}

input[type="range"] {
	height: 0;
}

button {
	color: #333;
	background-color: #f4f4f4;
	outline: none;
}

button:disabled {
	color: #999;
}

button:not(:disabled):active {
	background-color: #ddd;
}

button:focus {
	border-color: #666;
}*/

@font-face {
  font-family: "bootstrap-icons";
  src: url("/fonts/bootstrap-icons.woff2?8bd4575acf83c7696dc7a14a966660a3") format("woff2"),
url("/fonts/bootstrap-icons.woff?8bd4575acf83c7696dc7a14a966660a3") format("woff");
}

:global([class^="bi-"]::before),
:global([class*=" bi-"]::before) {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global(.bi-alarm-fill::before) { content: "\f101"; }

:global(.bi-alarm::before) { content: "\f102"; }

:global(.bi-align-bottom::before) { content: "\f103"; }

:global(.bi-align-center::before) { content: "\f104"; }

:global(.bi-align-end::before) { content: "\f105"; }

:global(.bi-align-middle::before) { content: "\f106"; }

:global(.bi-align-start::before) { content: "\f107"; }

:global(.bi-align-top::before) { content: "\f108"; }

:global(.bi-alt::before) { content: "\f109"; }

:global(.bi-app-indicator::before) { content: "\f10a"; }

:global(.bi-app::before) { content: "\f10b"; }

:global(.bi-archive-fill::before) { content: "\f10c"; }

:global(.bi-archive::before) { content: "\f10d"; }

:global(.bi-arrow-90deg-down::before) { content: "\f10e"; }

:global(.bi-arrow-90deg-left::before) { content: "\f10f"; }

:global(.bi-arrow-90deg-right::before) { content: "\f110"; }

:global(.bi-arrow-90deg-up::before) { content: "\f111"; }

:global(.bi-arrow-bar-down::before) { content: "\f112"; }

:global(.bi-arrow-bar-left::before) { content: "\f113"; }

:global(.bi-arrow-bar-right::before) { content: "\f114"; }

:global(.bi-arrow-bar-up::before) { content: "\f115"; }

:global(.bi-arrow-clockwise::before) { content: "\f116"; }

:global(.bi-arrow-counterclockwise::before) { content: "\f117"; }

:global(.bi-arrow-down-circle-fill::before) { content: "\f118"; }

:global(.bi-arrow-down-circle::before) { content: "\f119"; }

:global(.bi-arrow-down-left-circle-fill::before) { content: "\f11a"; }

:global(.bi-arrow-down-left-circle::before) { content: "\f11b"; }

:global(.bi-arrow-down-left-square-fill::before) { content: "\f11c"; }

:global(.bi-arrow-down-left-square::before) { content: "\f11d"; }

:global(.bi-arrow-down-left::before) { content: "\f11e"; }

:global(.bi-arrow-down-right-circle-fill::before) { content: "\f11f"; }

:global(.bi-arrow-down-right-circle::before) { content: "\f120"; }

:global(.bi-arrow-down-right-square-fill::before) { content: "\f121"; }

:global(.bi-arrow-down-right-square::before) { content: "\f122"; }

:global(.bi-arrow-down-right::before) { content: "\f123"; }

:global(.bi-arrow-down-short::before) { content: "\f124"; }

:global(.bi-arrow-down-square-fill::before) { content: "\f125"; }

:global(.bi-arrow-down-square::before) { content: "\f126"; }

:global(.bi-arrow-down-up::before) { content: "\f127"; }

:global(.bi-arrow-down::before) { content: "\f128"; }

:global(.bi-arrow-left-circle-fill::before) { content: "\f129"; }

:global(.bi-arrow-left-circle::before) { content: "\f12a"; }

:global(.bi-arrow-left-right::before) { content: "\f12b"; }

:global(.bi-arrow-left-short::before) { content: "\f12c"; }

:global(.bi-arrow-left-square-fill::before) { content: "\f12d"; }

:global(.bi-arrow-left-square::before) { content: "\f12e"; }

:global(.bi-arrow-left::before) { content: "\f12f"; }

:global(.bi-arrow-repeat::before) { content: "\f130"; }

:global(.bi-arrow-return-left::before) { content: "\f131"; }

:global(.bi-arrow-return-right::before) { content: "\f132"; }

:global(.bi-arrow-right-circle-fill::before) { content: "\f133"; }

:global(.bi-arrow-right-circle::before) { content: "\f134"; }

:global(.bi-arrow-right-short::before) { content: "\f135"; }

:global(.bi-arrow-right-square-fill::before) { content: "\f136"; }

:global(.bi-arrow-right-square::before) { content: "\f137"; }

:global(.bi-arrow-right::before) { content: "\f138"; }

:global(.bi-arrow-up-circle-fill::before) { content: "\f139"; }

:global(.bi-arrow-up-circle::before) { content: "\f13a"; }

:global(.bi-arrow-up-left-circle-fill::before) { content: "\f13b"; }

:global(.bi-arrow-up-left-circle::before) { content: "\f13c"; }

:global(.bi-arrow-up-left-square-fill::before) { content: "\f13d"; }

:global(.bi-arrow-up-left-square::before) { content: "\f13e"; }

:global(.bi-arrow-up-left::before) { content: "\f13f"; }

:global(.bi-arrow-up-right-circle-fill::before) { content: "\f140"; }

:global(.bi-arrow-up-right-circle::before) { content: "\f141"; }

:global(.bi-arrow-up-right-square-fill::before) { content: "\f142"; }

:global(.bi-arrow-up-right-square::before) { content: "\f143"; }

:global(.bi-arrow-up-right::before) { content: "\f144"; }

:global(.bi-arrow-up-short::before) { content: "\f145"; }

:global(.bi-arrow-up-square-fill::before) { content: "\f146"; }

:global(.bi-arrow-up-square::before) { content: "\f147"; }

:global(.bi-arrow-up::before) { content: "\f148"; }

:global(.bi-arrows-angle-contract::before) { content: "\f149"; }

:global(.bi-arrows-angle-expand::before) { content: "\f14a"; }

:global(.bi-arrows-collapse::before) { content: "\f14b"; }

:global(.bi-arrows-expand::before) { content: "\f14c"; }

:global(.bi-arrows-fullscreen::before) { content: "\f14d"; }

:global(.bi-arrows-move::before) { content: "\f14e"; }

:global(.bi-aspect-ratio-fill::before) { content: "\f14f"; }

:global(.bi-aspect-ratio::before) { content: "\f150"; }

:global(.bi-asterisk::before) { content: "\f151"; }

:global(.bi-at::before) { content: "\f152"; }

:global(.bi-award-fill::before) { content: "\f153"; }

:global(.bi-award::before) { content: "\f154"; }

:global(.bi-back::before) { content: "\f155"; }

:global(.bi-backspace-fill::before) { content: "\f156"; }

:global(.bi-backspace-reverse-fill::before) { content: "\f157"; }

:global(.bi-backspace-reverse::before) { content: "\f158"; }

:global(.bi-backspace::before) { content: "\f159"; }

:global(.bi-badge-3d-fill::before) { content: "\f15a"; }

:global(.bi-badge-3d::before) { content: "\f15b"; }

:global(.bi-badge-4k-fill::before) { content: "\f15c"; }

:global(.bi-badge-4k::before) { content: "\f15d"; }

:global(.bi-badge-8k-fill::before) { content: "\f15e"; }

:global(.bi-badge-8k::before) { content: "\f15f"; }

:global(.bi-badge-ad-fill::before) { content: "\f160"; }

:global(.bi-badge-ad::before) { content: "\f161"; }

:global(.bi-badge-ar-fill::before) { content: "\f162"; }

:global(.bi-badge-ar::before) { content: "\f163"; }

:global(.bi-badge-cc-fill::before) { content: "\f164"; }

:global(.bi-badge-cc::before) { content: "\f165"; }

:global(.bi-badge-hd-fill::before) { content: "\f166"; }

:global(.bi-badge-hd::before) { content: "\f167"; }

:global(.bi-badge-tm-fill::before) { content: "\f168"; }

:global(.bi-badge-tm::before) { content: "\f169"; }

:global(.bi-badge-vo-fill::before) { content: "\f16a"; }

:global(.bi-badge-vo::before) { content: "\f16b"; }

:global(.bi-badge-vr-fill::before) { content: "\f16c"; }

:global(.bi-badge-vr::before) { content: "\f16d"; }

:global(.bi-badge-wc-fill::before) { content: "\f16e"; }

:global(.bi-badge-wc::before) { content: "\f16f"; }

:global(.bi-bag-check-fill::before) { content: "\f170"; }

:global(.bi-bag-check::before) { content: "\f171"; }

:global(.bi-bag-dash-fill::before) { content: "\f172"; }

:global(.bi-bag-dash::before) { content: "\f173"; }

:global(.bi-bag-fill::before) { content: "\f174"; }

:global(.bi-bag-plus-fill::before) { content: "\f175"; }

:global(.bi-bag-plus::before) { content: "\f176"; }

:global(.bi-bag-x-fill::before) { content: "\f177"; }

:global(.bi-bag-x::before) { content: "\f178"; }

:global(.bi-bag::before) { content: "\f179"; }

:global(.bi-bar-chart-fill::before) { content: "\f17a"; }

:global(.bi-bar-chart-line-fill::before) { content: "\f17b"; }

:global(.bi-bar-chart-line::before) { content: "\f17c"; }

:global(.bi-bar-chart-steps::before) { content: "\f17d"; }

:global(.bi-bar-chart::before) { content: "\f17e"; }

:global(.bi-basket-fill::before) { content: "\f17f"; }

:global(.bi-basket::before) { content: "\f180"; }

:global(.bi-basket2-fill::before) { content: "\f181"; }

:global(.bi-basket2::before) { content: "\f182"; }

:global(.bi-basket3-fill::before) { content: "\f183"; }

:global(.bi-basket3::before) { content: "\f184"; }

:global(.bi-battery-charging::before) { content: "\f185"; }

:global(.bi-battery-full::before) { content: "\f186"; }

:global(.bi-battery-half::before) { content: "\f187"; }

:global(.bi-battery::before) { content: "\f188"; }

:global(.bi-bell-fill::before) { content: "\f189"; }

:global(.bi-bell::before) { content: "\f18a"; }

:global(.bi-bezier::before) { content: "\f18b"; }

:global(.bi-bezier2::before) { content: "\f18c"; }

:global(.bi-bicycle::before) { content: "\f18d"; }

:global(.bi-binoculars-fill::before) { content: "\f18e"; }

:global(.bi-binoculars::before) { content: "\f18f"; }

:global(.bi-blockquote-left::before) { content: "\f190"; }

:global(.bi-blockquote-right::before) { content: "\f191"; }

:global(.bi-book-fill::before) { content: "\f192"; }

:global(.bi-book-half::before) { content: "\f193"; }

:global(.bi-book::before) { content: "\f194"; }

:global(.bi-bookmark-check-fill::before) { content: "\f195"; }

:global(.bi-bookmark-check::before) { content: "\f196"; }

:global(.bi-bookmark-dash-fill::before) { content: "\f197"; }

:global(.bi-bookmark-dash::before) { content: "\f198"; }

:global(.bi-bookmark-fill::before) { content: "\f199"; }

:global(.bi-bookmark-heart-fill::before) { content: "\f19a"; }

:global(.bi-bookmark-heart::before) { content: "\f19b"; }

:global(.bi-bookmark-plus-fill::before) { content: "\f19c"; }

:global(.bi-bookmark-plus::before) { content: "\f19d"; }

:global(.bi-bookmark-star-fill::before) { content: "\f19e"; }

:global(.bi-bookmark-star::before) { content: "\f19f"; }

:global(.bi-bookmark-x-fill::before) { content: "\f1a0"; }

:global(.bi-bookmark-x::before) { content: "\f1a1"; }

:global(.bi-bookmark::before) { content: "\f1a2"; }

:global(.bi-bookmarks-fill::before) { content: "\f1a3"; }

:global(.bi-bookmarks::before) { content: "\f1a4"; }

:global(.bi-bookshelf::before) { content: "\f1a5"; }

:global(.bi-bootstrap-fill::before) { content: "\f1a6"; }

:global(.bi-bootstrap-reboot::before) { content: "\f1a7"; }

:global(.bi-bootstrap::before) { content: "\f1a8"; }

:global(.bi-border-all::before) { content: "\f1a9"; }

:global(.bi-border-bottom::before) { content: "\f1aa"; }

:global(.bi-border-center::before) { content: "\f1ab"; }

:global(.bi-border-inner::before) { content: "\f1ac"; }

:global(.bi-border-left::before) { content: "\f1ad"; }

:global(.bi-border-middle::before) { content: "\f1ae"; }

:global(.bi-border-outer::before) { content: "\f1af"; }

:global(.bi-border-right::before) { content: "\f1b0"; }

:global(.bi-border-style::before) { content: "\f1b1"; }

:global(.bi-border-top::before) { content: "\f1b2"; }

:global(.bi-border-width::before) { content: "\f1b3"; }

:global(.bi-border::before) { content: "\f1b4"; }

:global(.bi-bounding-box-circles::before) { content: "\f1b5"; }

:global(.bi-bounding-box::before) { content: "\f1b6"; }

:global(.bi-box-arrow-down-left::before) { content: "\f1b7"; }

:global(.bi-box-arrow-down-right::before) { content: "\f1b8"; }

:global(.bi-box-arrow-down::before) { content: "\f1b9"; }

:global(.bi-box-arrow-in-down-left::before) { content: "\f1ba"; }

:global(.bi-box-arrow-in-down-right::before) { content: "\f1bb"; }

:global(.bi-box-arrow-in-down::before) { content: "\f1bc"; }

:global(.bi-box-arrow-in-left::before) { content: "\f1bd"; }

:global(.bi-box-arrow-in-right::before) { content: "\f1be"; }

:global(.bi-box-arrow-in-up-left::before) { content: "\f1bf"; }

:global(.bi-box-arrow-in-up-right::before) { content: "\f1c0"; }

:global(.bi-box-arrow-in-up::before) { content: "\f1c1"; }

:global(.bi-box-arrow-left::before) { content: "\f1c2"; }

:global(.bi-box-arrow-right::before) { content: "\f1c3"; }

:global(.bi-box-arrow-up-left::before) { content: "\f1c4"; }

:global(.bi-box-arrow-up-right::before) { content: "\f1c5"; }

:global(.bi-box-arrow-up::before) { content: "\f1c6"; }

:global(.bi-box-seam::before) { content: "\f1c7"; }

:global(.bi-box::before) { content: "\f1c8"; }

:global(.bi-braces::before) { content: "\f1c9"; }

:global(.bi-bricks::before) { content: "\f1ca"; }

:global(.bi-briefcase-fill::before) { content: "\f1cb"; }

:global(.bi-briefcase::before) { content: "\f1cc"; }

:global(.bi-brightness-alt-high-fill::before) { content: "\f1cd"; }

:global(.bi-brightness-alt-high::before) { content: "\f1ce"; }

:global(.bi-brightness-alt-low-fill::before) { content: "\f1cf"; }

:global(.bi-brightness-alt-low::before) { content: "\f1d0"; }

:global(.bi-brightness-high-fill::before) { content: "\f1d1"; }

:global(.bi-brightness-high::before) { content: "\f1d2"; }

:global(.bi-brightness-low-fill::before) { content: "\f1d3"; }

:global(.bi-brightness-low::before) { content: "\f1d4"; }

:global(.bi-broadcast-pin::before) { content: "\f1d5"; }

:global(.bi-broadcast::before) { content: "\f1d6"; }

:global(.bi-brush-fill::before) { content: "\f1d7"; }

:global(.bi-brush::before) { content: "\f1d8"; }

:global(.bi-bucket-fill::before) { content: "\f1d9"; }

:global(.bi-bucket::before) { content: "\f1da"; }

:global(.bi-bug-fill::before) { content: "\f1db"; }

:global(.bi-bug::before) { content: "\f1dc"; }

:global(.bi-building::before) { content: "\f1dd"; }

:global(.bi-bullseye::before) { content: "\f1de"; }

:global(.bi-calculator-fill::before) { content: "\f1df"; }

:global(.bi-calculator::before) { content: "\f1e0"; }

:global(.bi-calendar-check-fill::before) { content: "\f1e1"; }

:global(.bi-calendar-check::before) { content: "\f1e2"; }

:global(.bi-calendar-date-fill::before) { content: "\f1e3"; }

:global(.bi-calendar-date::before) { content: "\f1e4"; }

:global(.bi-calendar-day-fill::before) { content: "\f1e5"; }

:global(.bi-calendar-day::before) { content: "\f1e6"; }

:global(.bi-calendar-event-fill::before) { content: "\f1e7"; }

:global(.bi-calendar-event::before) { content: "\f1e8"; }

:global(.bi-calendar-fill::before) { content: "\f1e9"; }

:global(.bi-calendar-minus-fill::before) { content: "\f1ea"; }

:global(.bi-calendar-minus::before) { content: "\f1eb"; }

:global(.bi-calendar-month-fill::before) { content: "\f1ec"; }

:global(.bi-calendar-month::before) { content: "\f1ed"; }

:global(.bi-calendar-plus-fill::before) { content: "\f1ee"; }

:global(.bi-calendar-plus::before) { content: "\f1ef"; }

:global(.bi-calendar-range-fill::before) { content: "\f1f0"; }

:global(.bi-calendar-range::before) { content: "\f1f1"; }

:global(.bi-calendar-week-fill::before) { content: "\f1f2"; }

:global(.bi-calendar-week::before) { content: "\f1f3"; }

:global(.bi-calendar-x-fill::before) { content: "\f1f4"; }

:global(.bi-calendar-x::before) { content: "\f1f5"; }

:global(.bi-calendar::before) { content: "\f1f6"; }

:global(.bi-calendar2-check-fill::before) { content: "\f1f7"; }

:global(.bi-calendar2-check::before) { content: "\f1f8"; }

:global(.bi-calendar2-date-fill::before) { content: "\f1f9"; }

:global(.bi-calendar2-date::before) { content: "\f1fa"; }

:global(.bi-calendar2-day-fill::before) { content: "\f1fb"; }

:global(.bi-calendar2-day::before) { content: "\f1fc"; }

:global(.bi-calendar2-event-fill::before) { content: "\f1fd"; }

:global(.bi-calendar2-event::before) { content: "\f1fe"; }

:global(.bi-calendar2-fill::before) { content: "\f1ff"; }

:global(.bi-calendar2-minus-fill::before) { content: "\f200"; }

:global(.bi-calendar2-minus::before) { content: "\f201"; }

:global(.bi-calendar2-month-fill::before) { content: "\f202"; }

:global(.bi-calendar2-month::before) { content: "\f203"; }

:global(.bi-calendar2-plus-fill::before) { content: "\f204"; }

:global(.bi-calendar2-plus::before) { content: "\f205"; }

:global(.bi-calendar2-range-fill::before) { content: "\f206"; }

:global(.bi-calendar2-range::before) { content: "\f207"; }

:global(.bi-calendar2-week-fill::before) { content: "\f208"; }

:global(.bi-calendar2-week::before) { content: "\f209"; }

:global(.bi-calendar2-x-fill::before) { content: "\f20a"; }

:global(.bi-calendar2-x::before) { content: "\f20b"; }

:global(.bi-calendar2::before) { content: "\f20c"; }

:global(.bi-calendar3-event-fill::before) { content: "\f20d"; }

:global(.bi-calendar3-event::before) { content: "\f20e"; }

:global(.bi-calendar3-fill::before) { content: "\f20f"; }

:global(.bi-calendar3-range-fill::before) { content: "\f210"; }

:global(.bi-calendar3-range::before) { content: "\f211"; }

:global(.bi-calendar3-week-fill::before) { content: "\f212"; }

:global(.bi-calendar3-week::before) { content: "\f213"; }

:global(.bi-calendar3::before) { content: "\f214"; }

:global(.bi-calendar4-event::before) { content: "\f215"; }

:global(.bi-calendar4-range::before) { content: "\f216"; }

:global(.bi-calendar4-week::before) { content: "\f217"; }

:global(.bi-calendar4::before) { content: "\f218"; }

:global(.bi-camera-fill::before) { content: "\f219"; }

:global(.bi-camera-reels-fill::before) { content: "\f21a"; }

:global(.bi-camera-reels::before) { content: "\f21b"; }

:global(.bi-camera-video-fill::before) { content: "\f21c"; }

:global(.bi-camera-video-off-fill::before) { content: "\f21d"; }

:global(.bi-camera-video-off::before) { content: "\f21e"; }

:global(.bi-camera-video::before) { content: "\f21f"; }

:global(.bi-camera::before) { content: "\f220"; }

:global(.bi-camera2::before) { content: "\f221"; }

:global(.bi-capslock-fill::before) { content: "\f222"; }

:global(.bi-capslock::before) { content: "\f223"; }

:global(.bi-card-checklist::before) { content: "\f224"; }

:global(.bi-card-heading::before) { content: "\f225"; }

:global(.bi-card-image::before) { content: "\f226"; }

:global(.bi-card-list::before) { content: "\f227"; }

:global(.bi-card-text::before) { content: "\f228"; }

:global(.bi-caret-down-fill::before) { content: "\f229"; }

:global(.bi-caret-down-square-fill::before) { content: "\f22a"; }

:global(.bi-caret-down-square::before) { content: "\f22b"; }

:global(.bi-caret-down::before) { content: "\f22c"; }

:global(.bi-caret-left-fill::before) { content: "\f22d"; }

:global(.bi-caret-left-square-fill::before) { content: "\f22e"; }

:global(.bi-caret-left-square::before) { content: "\f22f"; }

:global(.bi-caret-left::before) { content: "\f230"; }

:global(.bi-caret-right-fill::before) { content: "\f231"; }

:global(.bi-caret-right-square-fill::before) { content: "\f232"; }

:global(.bi-caret-right-square::before) { content: "\f233"; }

:global(.bi-caret-right::before) { content: "\f234"; }

:global(.bi-caret-up-fill::before) { content: "\f235"; }

:global(.bi-caret-up-square-fill::before) { content: "\f236"; }

:global(.bi-caret-up-square::before) { content: "\f237"; }

:global(.bi-caret-up::before) { content: "\f238"; }

:global(.bi-cart-check-fill::before) { content: "\f239"; }

:global(.bi-cart-check::before) { content: "\f23a"; }

:global(.bi-cart-dash-fill::before) { content: "\f23b"; }

:global(.bi-cart-dash::before) { content: "\f23c"; }

:global(.bi-cart-fill::before) { content: "\f23d"; }

:global(.bi-cart-plus-fill::before) { content: "\f23e"; }

:global(.bi-cart-plus::before) { content: "\f23f"; }

:global(.bi-cart-x-fill::before) { content: "\f240"; }

:global(.bi-cart-x::before) { content: "\f241"; }

:global(.bi-cart::before) { content: "\f242"; }

:global(.bi-cart2::before) { content: "\f243"; }

:global(.bi-cart3::before) { content: "\f244"; }

:global(.bi-cart4::before) { content: "\f245"; }

:global(.bi-cash-stack::before) { content: "\f246"; }

:global(.bi-cash::before) { content: "\f247"; }

:global(.bi-cast::before) { content: "\f248"; }

:global(.bi-chat-dots-fill::before) { content: "\f249"; }

:global(.bi-chat-dots::before) { content: "\f24a"; }

:global(.bi-chat-fill::before) { content: "\f24b"; }

:global(.bi-chat-left-dots-fill::before) { content: "\f24c"; }

:global(.bi-chat-left-dots::before) { content: "\f24d"; }

:global(.bi-chat-left-fill::before) { content: "\f24e"; }

:global(.bi-chat-left-quote-fill::before) { content: "\f24f"; }

:global(.bi-chat-left-quote::before) { content: "\f250"; }

:global(.bi-chat-left-text-fill::before) { content: "\f251"; }

:global(.bi-chat-left-text::before) { content: "\f252"; }

:global(.bi-chat-left::before) { content: "\f253"; }

:global(.bi-chat-quote-fill::before) { content: "\f254"; }

:global(.bi-chat-quote::before) { content: "\f255"; }

:global(.bi-chat-right-dots-fill::before) { content: "\f256"; }

:global(.bi-chat-right-dots::before) { content: "\f257"; }

:global(.bi-chat-right-fill::before) { content: "\f258"; }

:global(.bi-chat-right-quote-fill::before) { content: "\f259"; }

:global(.bi-chat-right-quote::before) { content: "\f25a"; }

:global(.bi-chat-right-text-fill::before) { content: "\f25b"; }

:global(.bi-chat-right-text::before) { content: "\f25c"; }

:global(.bi-chat-right::before) { content: "\f25d"; }

:global(.bi-chat-square-dots-fill::before) { content: "\f25e"; }

:global(.bi-chat-square-dots::before) { content: "\f25f"; }

:global(.bi-chat-square-fill::before) { content: "\f260"; }

:global(.bi-chat-square-quote-fill::before) { content: "\f261"; }

:global(.bi-chat-square-quote::before) { content: "\f262"; }

:global(.bi-chat-square-text-fill::before) { content: "\f263"; }

:global(.bi-chat-square-text::before) { content: "\f264"; }

:global(.bi-chat-square::before) { content: "\f265"; }

:global(.bi-chat-text-fill::before) { content: "\f266"; }

:global(.bi-chat-text::before) { content: "\f267"; }

:global(.bi-chat::before) { content: "\f268"; }

:global(.bi-check-all::before) { content: "\f269"; }

:global(.bi-check-circle-fill::before) { content: "\f26a"; }

:global(.bi-check-circle::before) { content: "\f26b"; }

:global(.bi-check-square-fill::before) { content: "\f26c"; }

:global(.bi-check-square::before) { content: "\f26d"; }

:global(.bi-check::before) { content: "\f26e"; }

:global(.bi-check2-all::before) { content: "\f26f"; }

:global(.bi-check2-circle::before) { content: "\f270"; }

:global(.bi-check2-square::before) { content: "\f271"; }

:global(.bi-check2::before) { content: "\f272"; }

:global(.bi-chevron-bar-contract::before) { content: "\f273"; }

:global(.bi-chevron-bar-down::before) { content: "\f274"; }

:global(.bi-chevron-bar-expand::before) { content: "\f275"; }

:global(.bi-chevron-bar-left::before) { content: "\f276"; }

:global(.bi-chevron-bar-right::before) { content: "\f277"; }

:global(.bi-chevron-bar-up::before) { content: "\f278"; }

:global(.bi-chevron-compact-down::before) { content: "\f279"; }

:global(.bi-chevron-compact-left::before) { content: "\f27a"; }

:global(.bi-chevron-compact-right::before) { content: "\f27b"; }

:global(.bi-chevron-compact-up::before) { content: "\f27c"; }

:global(.bi-chevron-contract::before) { content: "\f27d"; }

:global(.bi-chevron-double-down::before) { content: "\f27e"; }

:global(.bi-chevron-double-left::before) { content: "\f27f"; }

:global(.bi-chevron-double-right::before) { content: "\f280"; }

:global(.bi-chevron-double-up::before) { content: "\f281"; }

:global(.bi-chevron-down::before) { content: "\f282"; }

:global(.bi-chevron-expand::before) { content: "\f283"; }

:global(.bi-chevron-left::before) { content: "\f284"; }

:global(.bi-chevron-right::before) { content: "\f285"; }

:global(.bi-chevron-up::before) { content: "\f286"; }

:global(.bi-circle-fill::before) { content: "\f287"; }

:global(.bi-circle-half::before) { content: "\f288"; }

:global(.bi-circle-square::before) { content: "\f289"; }

:global(.bi-circle::before) { content: "\f28a"; }

:global(.bi-clipboard-check::before) { content: "\f28b"; }

:global(.bi-clipboard-data::before) { content: "\f28c"; }

:global(.bi-clipboard-minus::before) { content: "\f28d"; }

:global(.bi-clipboard-plus::before) { content: "\f28e"; }

:global(.bi-clipboard-x::before) { content: "\f28f"; }

:global(.bi-clipboard::before) { content: "\f290"; }

:global(.bi-clock-fill::before) { content: "\f291"; }

:global(.bi-clock-history::before) { content: "\f292"; }

:global(.bi-clock::before) { content: "\f293"; }

:global(.bi-cloud-arrow-down-fill::before) { content: "\f294"; }

:global(.bi-cloud-arrow-down::before) { content: "\f295"; }

:global(.bi-cloud-arrow-up-fill::before) { content: "\f296"; }

:global(.bi-cloud-arrow-up::before) { content: "\f297"; }

:global(.bi-cloud-check-fill::before) { content: "\f298"; }

:global(.bi-cloud-check::before) { content: "\f299"; }

:global(.bi-cloud-download-fill::before) { content: "\f29a"; }

:global(.bi-cloud-download::before) { content: "\f29b"; }

:global(.bi-cloud-drizzle-fill::before) { content: "\f29c"; }

:global(.bi-cloud-drizzle::before) { content: "\f29d"; }

:global(.bi-cloud-fill::before) { content: "\f29e"; }

:global(.bi-cloud-fog-fill::before) { content: "\f29f"; }

:global(.bi-cloud-fog::before) { content: "\f2a0"; }

:global(.bi-cloud-fog2-fill::before) { content: "\f2a1"; }

:global(.bi-cloud-fog2::before) { content: "\f2a2"; }

:global(.bi-cloud-hail-fill::before) { content: "\f2a3"; }

:global(.bi-cloud-hail::before) { content: "\f2a4"; }

:global(.bi-cloud-haze-1::before) { content: "\f2a5"; }

:global(.bi-cloud-haze-fill::before) { content: "\f2a6"; }

:global(.bi-cloud-haze::before) { content: "\f2a7"; }

:global(.bi-cloud-haze2-fill::before) { content: "\f2a8"; }

:global(.bi-cloud-lightning-fill::before) { content: "\f2a9"; }

:global(.bi-cloud-lightning-rain-fill::before) { content: "\f2aa"; }

:global(.bi-cloud-lightning-rain::before) { content: "\f2ab"; }

:global(.bi-cloud-lightning::before) { content: "\f2ac"; }

:global(.bi-cloud-minus-fill::before) { content: "\f2ad"; }

:global(.bi-cloud-minus::before) { content: "\f2ae"; }

:global(.bi-cloud-moon-fill::before) { content: "\f2af"; }

:global(.bi-cloud-moon::before) { content: "\f2b0"; }

:global(.bi-cloud-plus-fill::before) { content: "\f2b1"; }

:global(.bi-cloud-plus::before) { content: "\f2b2"; }

:global(.bi-cloud-rain-fill::before) { content: "\f2b3"; }

:global(.bi-cloud-rain-heavy-fill::before) { content: "\f2b4"; }

:global(.bi-cloud-rain-heavy::before) { content: "\f2b5"; }

:global(.bi-cloud-rain::before) { content: "\f2b6"; }

:global(.bi-cloud-slash-fill::before) { content: "\f2b7"; }

:global(.bi-cloud-slash::before) { content: "\f2b8"; }

:global(.bi-cloud-sleet-fill::before) { content: "\f2b9"; }

:global(.bi-cloud-sleet::before) { content: "\f2ba"; }

:global(.bi-cloud-snow-fill::before) { content: "\f2bb"; }

:global(.bi-cloud-snow::before) { content: "\f2bc"; }

:global(.bi-cloud-sun-fill::before) { content: "\f2bd"; }

:global(.bi-cloud-sun::before) { content: "\f2be"; }

:global(.bi-cloud-upload-fill::before) { content: "\f2bf"; }

:global(.bi-cloud-upload::before) { content: "\f2c0"; }

:global(.bi-cloud::before) { content: "\f2c1"; }

:global(.bi-clouds-fill::before) { content: "\f2c2"; }

:global(.bi-clouds::before) { content: "\f2c3"; }

:global(.bi-cloudy-fill::before) { content: "\f2c4"; }

:global(.bi-cloudy::before) { content: "\f2c5"; }

:global(.bi-code-slash::before) { content: "\f2c6"; }

:global(.bi-code-square::before) { content: "\f2c7"; }

:global(.bi-code::before) { content: "\f2c8"; }

:global(.bi-collection-fill::before) { content: "\f2c9"; }

:global(.bi-collection-play-fill::before) { content: "\f2ca"; }

:global(.bi-collection-play::before) { content: "\f2cb"; }

:global(.bi-collection::before) { content: "\f2cc"; }

:global(.bi-columns-gap::before) { content: "\f2cd"; }

:global(.bi-columns::before) { content: "\f2ce"; }

:global(.bi-command::before) { content: "\f2cf"; }

:global(.bi-compass-fill::before) { content: "\f2d0"; }

:global(.bi-compass::before) { content: "\f2d1"; }

:global(.bi-cone-striped::before) { content: "\f2d2"; }

:global(.bi-cone::before) { content: "\f2d3"; }

:global(.bi-controller::before) { content: "\f2d4"; }

:global(.bi-cpu-fill::before) { content: "\f2d5"; }

:global(.bi-cpu::before) { content: "\f2d6"; }

:global(.bi-credit-card-2-back-fill::before) { content: "\f2d7"; }

:global(.bi-credit-card-2-back::before) { content: "\f2d8"; }

:global(.bi-credit-card-2-front-fill::before) { content: "\f2d9"; }

:global(.bi-credit-card-2-front::before) { content: "\f2da"; }

:global(.bi-credit-card-fill::before) { content: "\f2db"; }

:global(.bi-credit-card::before) { content: "\f2dc"; }

:global(.bi-crop::before) { content: "\f2dd"; }

:global(.bi-cup-fill::before) { content: "\f2de"; }

:global(.bi-cup-straw::before) { content: "\f2df"; }

:global(.bi-cup::before) { content: "\f2e0"; }

:global(.bi-cursor-fill::before) { content: "\f2e1"; }

:global(.bi-cursor-text::before) { content: "\f2e2"; }

:global(.bi-cursor::before) { content: "\f2e3"; }

:global(.bi-dash-circle-dotted::before) { content: "\f2e4"; }

:global(.bi-dash-circle-fill::before) { content: "\f2e5"; }

:global(.bi-dash-circle::before) { content: "\f2e6"; }

:global(.bi-dash-square-dotted::before) { content: "\f2e7"; }

:global(.bi-dash-square-fill::before) { content: "\f2e8"; }

:global(.bi-dash-square::before) { content: "\f2e9"; }

:global(.bi-dash::before) { content: "\f2ea"; }

:global(.bi-diagram-2-fill::before) { content: "\f2eb"; }

:global(.bi-diagram-2::before) { content: "\f2ec"; }

:global(.bi-diagram-3-fill::before) { content: "\f2ed"; }

:global(.bi-diagram-3::before) { content: "\f2ee"; }

:global(.bi-diamond-fill::before) { content: "\f2ef"; }

:global(.bi-diamond-half::before) { content: "\f2f0"; }

:global(.bi-diamond::before) { content: "\f2f1"; }

:global(.bi-dice-1-fill::before) { content: "\f2f2"; }

:global(.bi-dice-1::before) { content: "\f2f3"; }

:global(.bi-dice-2-fill::before) { content: "\f2f4"; }

:global(.bi-dice-2::before) { content: "\f2f5"; }

:global(.bi-dice-3-fill::before) { content: "\f2f6"; }

:global(.bi-dice-3::before) { content: "\f2f7"; }

:global(.bi-dice-4-fill::before) { content: "\f2f8"; }

:global(.bi-dice-4::before) { content: "\f2f9"; }

:global(.bi-dice-5-fill::before) { content: "\f2fa"; }

:global(.bi-dice-5::before) { content: "\f2fb"; }

:global(.bi-dice-6-fill::before) { content: "\f2fc"; }

:global(.bi-dice-6::before) { content: "\f2fd"; }

:global(.bi-disc-fill::before) { content: "\f2fe"; }

:global(.bi-disc::before) { content: "\f2ff"; }

:global(.bi-discord::before) { content: "\f300"; }

:global(.bi-display-fill::before) { content: "\f301"; }

:global(.bi-display::before) { content: "\f302"; }

:global(.bi-distribute-horizontal::before) { content: "\f303"; }

:global(.bi-distribute-vertical::before) { content: "\f304"; }

:global(.bi-door-closed-fill::before) { content: "\f305"; }

:global(.bi-door-closed::before) { content: "\f306"; }

:global(.bi-door-open-fill::before) { content: "\f307"; }

:global(.bi-door-open::before) { content: "\f308"; }

:global(.bi-dot::before) { content: "\f309"; }

:global(.bi-download::before) { content: "\f30a"; }

:global(.bi-droplet-fill::before) { content: "\f30b"; }

:global(.bi-droplet-half::before) { content: "\f30c"; }

:global(.bi-droplet::before) { content: "\f30d"; }

:global(.bi-earbuds::before) { content: "\f30e"; }

:global(.bi-easel-fill::before) { content: "\f30f"; }

:global(.bi-easel::before) { content: "\f310"; }

:global(.bi-egg-fill::before) { content: "\f311"; }

:global(.bi-egg-fried::before) { content: "\f312"; }

:global(.bi-egg::before) { content: "\f313"; }

:global(.bi-eject-fill::before) { content: "\f314"; }

:global(.bi-eject::before) { content: "\f315"; }

:global(.bi-emoji-angry-fill::before) { content: "\f316"; }

:global(.bi-emoji-angry::before) { content: "\f317"; }

:global(.bi-emoji-dizzy-fill::before) { content: "\f318"; }

:global(.bi-emoji-dizzy::before) { content: "\f319"; }

:global(.bi-emoji-expressionless-fill::before) { content: "\f31a"; }

:global(.bi-emoji-expressionless::before) { content: "\f31b"; }

:global(.bi-emoji-frown-fill::before) { content: "\f31c"; }

:global(.bi-emoji-frown::before) { content: "\f31d"; }

:global(.bi-emoji-heart-eyes-fill::before) { content: "\f31e"; }

:global(.bi-emoji-heart-eyes::before) { content: "\f31f"; }

:global(.bi-emoji-laughing-fill::before) { content: "\f320"; }

:global(.bi-emoji-laughing::before) { content: "\f321"; }

:global(.bi-emoji-neutral-fill::before) { content: "\f322"; }

:global(.bi-emoji-neutral::before) { content: "\f323"; }

:global(.bi-emoji-smile-fill::before) { content: "\f324"; }

:global(.bi-emoji-smile-upside-down-fill::before) { content: "\f325"; }

:global(.bi-emoji-smile-upside-down::before) { content: "\f326"; }

:global(.bi-emoji-smile::before) { content: "\f327"; }

:global(.bi-emoji-sunglasses-fill::before) { content: "\f328"; }

:global(.bi-emoji-sunglasses::before) { content: "\f329"; }

:global(.bi-emoji-wink-fill::before) { content: "\f32a"; }

:global(.bi-emoji-wink::before) { content: "\f32b"; }

:global(.bi-envelope-fill::before) { content: "\f32c"; }

:global(.bi-envelope-open-fill::before) { content: "\f32d"; }

:global(.bi-envelope-open::before) { content: "\f32e"; }

:global(.bi-envelope::before) { content: "\f32f"; }

:global(.bi-eraser-fill::before) { content: "\f330"; }

:global(.bi-eraser::before) { content: "\f331"; }

:global(.bi-exclamation-circle-fill::before) { content: "\f332"; }

:global(.bi-exclamation-circle::before) { content: "\f333"; }

:global(.bi-exclamation-diamond-fill::before) { content: "\f334"; }

:global(.bi-exclamation-diamond::before) { content: "\f335"; }

:global(.bi-exclamation-octagon-fill::before) { content: "\f336"; }

:global(.bi-exclamation-octagon::before) { content: "\f337"; }

:global(.bi-exclamation-square-fill::before) { content: "\f338"; }

:global(.bi-exclamation-square::before) { content: "\f339"; }

:global(.bi-exclamation-triangle-fill::before) { content: "\f33a"; }

:global(.bi-exclamation-triangle::before) { content: "\f33b"; }

:global(.bi-exclamation::before) { content: "\f33c"; }

:global(.bi-exclude::before) { content: "\f33d"; }

:global(.bi-eye-fill::before) { content: "\f33e"; }

:global(.bi-eye-slash-fill::before) { content: "\f33f"; }

:global(.bi-eye-slash::before) { content: "\f340"; }

:global(.bi-eye::before) { content: "\f341"; }

:global(.bi-eyedropper::before) { content: "\f342"; }

:global(.bi-eyeglasses::before) { content: "\f343"; }

:global(.bi-facebook::before) { content: "\f344"; }

:global(.bi-file-arrow-down-fill::before) { content: "\f345"; }

:global(.bi-file-arrow-down::before) { content: "\f346"; }

:global(.bi-file-arrow-up-fill::before) { content: "\f347"; }

:global(.bi-file-arrow-up::before) { content: "\f348"; }

:global(.bi-file-bar-graph-fill::before) { content: "\f349"; }

:global(.bi-file-bar-graph::before) { content: "\f34a"; }

:global(.bi-file-binary-fill::before) { content: "\f34b"; }

:global(.bi-file-binary::before) { content: "\f34c"; }

:global(.bi-file-break-fill::before) { content: "\f34d"; }

:global(.bi-file-break::before) { content: "\f34e"; }

:global(.bi-file-check-fill::before) { content: "\f34f"; }

:global(.bi-file-check::before) { content: "\f350"; }

:global(.bi-file-code-fill::before) { content: "\f351"; }

:global(.bi-file-code::before) { content: "\f352"; }

:global(.bi-file-diff-fill::before) { content: "\f353"; }

:global(.bi-file-diff::before) { content: "\f354"; }

:global(.bi-file-earmark-arrow-down-fill::before) { content: "\f355"; }

:global(.bi-file-earmark-arrow-down::before) { content: "\f356"; }

:global(.bi-file-earmark-arrow-up-fill::before) { content: "\f357"; }

:global(.bi-file-earmark-arrow-up::before) { content: "\f358"; }

:global(.bi-file-earmark-bar-graph-fill::before) { content: "\f359"; }

:global(.bi-file-earmark-bar-graph::before) { content: "\f35a"; }

:global(.bi-file-earmark-binary-fill::before) { content: "\f35b"; }

:global(.bi-file-earmark-binary::before) { content: "\f35c"; }

:global(.bi-file-earmark-break-fill::before) { content: "\f35d"; }

:global(.bi-file-earmark-break::before) { content: "\f35e"; }

:global(.bi-file-earmark-check-fill::before) { content: "\f35f"; }

:global(.bi-file-earmark-check::before) { content: "\f360"; }

:global(.bi-file-earmark-code-fill::before) { content: "\f361"; }

:global(.bi-file-earmark-code::before) { content: "\f362"; }

:global(.bi-file-earmark-diff-fill::before) { content: "\f363"; }

:global(.bi-file-earmark-diff::before) { content: "\f364"; }

:global(.bi-file-earmark-easel-fill::before) { content: "\f365"; }

:global(.bi-file-earmark-easel::before) { content: "\f366"; }

:global(.bi-file-earmark-excel-fill::before) { content: "\f367"; }

:global(.bi-file-earmark-excel::before) { content: "\f368"; }

:global(.bi-file-earmark-fill::before) { content: "\f369"; }

:global(.bi-file-earmark-font-fill::before) { content: "\f36a"; }

:global(.bi-file-earmark-font::before) { content: "\f36b"; }

:global(.bi-file-earmark-image-fill::before) { content: "\f36c"; }

:global(.bi-file-earmark-image::before) { content: "\f36d"; }

:global(.bi-file-earmark-lock-fill::before) { content: "\f36e"; }

:global(.bi-file-earmark-lock::before) { content: "\f36f"; }

:global(.bi-file-earmark-lock2-fill::before) { content: "\f370"; }

:global(.bi-file-earmark-lock2::before) { content: "\f371"; }

:global(.bi-file-earmark-medical-fill::before) { content: "\f372"; }

:global(.bi-file-earmark-medical::before) { content: "\f373"; }

:global(.bi-file-earmark-minus-fill::before) { content: "\f374"; }

:global(.bi-file-earmark-minus::before) { content: "\f375"; }

:global(.bi-file-earmark-music-fill::before) { content: "\f376"; }

:global(.bi-file-earmark-music::before) { content: "\f377"; }

:global(.bi-file-earmark-person-fill::before) { content: "\f378"; }

:global(.bi-file-earmark-person::before) { content: "\f379"; }

:global(.bi-file-earmark-play-fill::before) { content: "\f37a"; }

:global(.bi-file-earmark-play::before) { content: "\f37b"; }

:global(.bi-file-earmark-plus-fill::before) { content: "\f37c"; }

:global(.bi-file-earmark-plus::before) { content: "\f37d"; }

:global(.bi-file-earmark-post-fill::before) { content: "\f37e"; }

:global(.bi-file-earmark-post::before) { content: "\f37f"; }

:global(.bi-file-earmark-ppt-fill::before) { content: "\f380"; }

:global(.bi-file-earmark-ppt::before) { content: "\f381"; }

:global(.bi-file-earmark-richtext-fill::before) { content: "\f382"; }

:global(.bi-file-earmark-richtext::before) { content: "\f383"; }

:global(.bi-file-earmark-ruled-fill::before) { content: "\f384"; }

:global(.bi-file-earmark-ruled::before) { content: "\f385"; }

:global(.bi-file-earmark-slides-fill::before) { content: "\f386"; }

:global(.bi-file-earmark-slides::before) { content: "\f387"; }

:global(.bi-file-earmark-spreadsheet-fill::before) { content: "\f388"; }

:global(.bi-file-earmark-spreadsheet::before) { content: "\f389"; }

:global(.bi-file-earmark-text-fill::before) { content: "\f38a"; }

:global(.bi-file-earmark-text::before) { content: "\f38b"; }

:global(.bi-file-earmark-word-fill::before) { content: "\f38c"; }

:global(.bi-file-earmark-word::before) { content: "\f38d"; }

:global(.bi-file-earmark-x-fill::before) { content: "\f38e"; }

:global(.bi-file-earmark-x::before) { content: "\f38f"; }

:global(.bi-file-earmark-zip-fill::before) { content: "\f390"; }

:global(.bi-file-earmark-zip::before) { content: "\f391"; }

:global(.bi-file-earmark::before) { content: "\f392"; }

:global(.bi-file-easel-fill::before) { content: "\f393"; }

:global(.bi-file-easel::before) { content: "\f394"; }

:global(.bi-file-excel-fill::before) { content: "\f395"; }

:global(.bi-file-excel::before) { content: "\f396"; }

:global(.bi-file-fill::before) { content: "\f397"; }

:global(.bi-file-font-fill::before) { content: "\f398"; }

:global(.bi-file-font::before) { content: "\f399"; }

:global(.bi-file-image-fill::before) { content: "\f39a"; }

:global(.bi-file-image::before) { content: "\f39b"; }

:global(.bi-file-lock-fill::before) { content: "\f39c"; }

:global(.bi-file-lock::before) { content: "\f39d"; }

:global(.bi-file-lock2-fill::before) { content: "\f39e"; }

:global(.bi-file-lock2::before) { content: "\f39f"; }

:global(.bi-file-medical-fill::before) { content: "\f3a0"; }

:global(.bi-file-medical::before) { content: "\f3a1"; }

:global(.bi-file-minus-fill::before) { content: "\f3a2"; }

:global(.bi-file-minus::before) { content: "\f3a3"; }

:global(.bi-file-music-fill::before) { content: "\f3a4"; }

:global(.bi-file-music::before) { content: "\f3a5"; }

:global(.bi-file-person-fill::before) { content: "\f3a6"; }

:global(.bi-file-person::before) { content: "\f3a7"; }

:global(.bi-file-play-fill::before) { content: "\f3a8"; }

:global(.bi-file-play::before) { content: "\f3a9"; }

:global(.bi-file-plus-fill::before) { content: "\f3aa"; }

:global(.bi-file-plus::before) { content: "\f3ab"; }

:global(.bi-file-post-fill::before) { content: "\f3ac"; }

:global(.bi-file-post::before) { content: "\f3ad"; }

:global(.bi-file-ppt-fill::before) { content: "\f3ae"; }

:global(.bi-file-ppt::before) { content: "\f3af"; }

:global(.bi-file-richtext-fill::before) { content: "\f3b0"; }

:global(.bi-file-richtext::before) { content: "\f3b1"; }

:global(.bi-file-ruled-fill::before) { content: "\f3b2"; }

:global(.bi-file-ruled::before) { content: "\f3b3"; }

:global(.bi-file-slides-fill::before) { content: "\f3b4"; }

:global(.bi-file-slides::before) { content: "\f3b5"; }

:global(.bi-file-spreadsheet-fill::before) { content: "\f3b6"; }

:global(.bi-file-spreadsheet::before) { content: "\f3b7"; }

:global(.bi-file-text-fill::before) { content: "\f3b8"; }

:global(.bi-file-text::before) { content: "\f3b9"; }

:global(.bi-file-word-fill::before) { content: "\f3ba"; }

:global(.bi-file-word::before) { content: "\f3bb"; }

:global(.bi-file-x-fill::before) { content: "\f3bc"; }

:global(.bi-file-x::before) { content: "\f3bd"; }

:global(.bi-file-zip-fill::before) { content: "\f3be"; }

:global(.bi-file-zip::before) { content: "\f3bf"; }

:global(.bi-file::before) { content: "\f3c0"; }

:global(.bi-files-alt::before) { content: "\f3c1"; }

:global(.bi-files::before) { content: "\f3c2"; }

:global(.bi-film::before) { content: "\f3c3"; }

:global(.bi-filter-circle-fill::before) { content: "\f3c4"; }

:global(.bi-filter-circle::before) { content: "\f3c5"; }

:global(.bi-filter-left::before) { content: "\f3c6"; }

:global(.bi-filter-right::before) { content: "\f3c7"; }

:global(.bi-filter-square-fill::before) { content: "\f3c8"; }

:global(.bi-filter-square::before) { content: "\f3c9"; }

:global(.bi-filter::before) { content: "\f3ca"; }

:global(.bi-flag-fill::before) { content: "\f3cb"; }

:global(.bi-flag::before) { content: "\f3cc"; }

:global(.bi-flower1::before) { content: "\f3cd"; }

:global(.bi-flower2::before) { content: "\f3ce"; }

:global(.bi-flower3::before) { content: "\f3cf"; }

:global(.bi-folder-check::before) { content: "\f3d0"; }

:global(.bi-folder-fill::before) { content: "\f3d1"; }

:global(.bi-folder-minus::before) { content: "\f3d2"; }

:global(.bi-folder-plus::before) { content: "\f3d3"; }

:global(.bi-folder-symlink-fill::before) { content: "\f3d4"; }

:global(.bi-folder-symlink::before) { content: "\f3d5"; }

:global(.bi-folder-x::before) { content: "\f3d6"; }

:global(.bi-folder::before) { content: "\f3d7"; }

:global(.bi-folder2-open::before) { content: "\f3d8"; }

:global(.bi-folder2::before) { content: "\f3d9"; }

:global(.bi-fonts::before) { content: "\f3da"; }

:global(.bi-forward-fill::before) { content: "\f3db"; }

:global(.bi-forward::before) { content: "\f3dc"; }

:global(.bi-front::before) { content: "\f3dd"; }

:global(.bi-fullscreen-exit::before) { content: "\f3de"; }

:global(.bi-fullscreen::before) { content: "\f3df"; }

:global(.bi-funnel-fill::before) { content: "\f3e0"; }

:global(.bi-funnel::before) { content: "\f3e1"; }

:global(.bi-gear-fill::before) { content: "\f3e2"; }

:global(.bi-gear-wide-connected::before) { content: "\f3e3"; }

:global(.bi-gear-wide::before) { content: "\f3e4"; }

:global(.bi-gear::before) { content: "\f3e5"; }

:global(.bi-gem::before) { content: "\f3e6"; }

:global(.bi-geo-alt-fill::before) { content: "\f3e7"; }

:global(.bi-geo-alt::before) { content: "\f3e8"; }

:global(.bi-geo-fill::before) { content: "\f3e9"; }

:global(.bi-geo::before) { content: "\f3ea"; }

:global(.bi-gift-fill::before) { content: "\f3eb"; }

:global(.bi-gift::before) { content: "\f3ec"; }

:global(.bi-github::before) { content: "\f3ed"; }

:global(.bi-globe::before) { content: "\f3ee"; }

:global(.bi-globe2::before) { content: "\f3ef"; }

:global(.bi-google::before) { content: "\f3f0"; }

:global(.bi-graph-down::before) { content: "\f3f1"; }

:global(.bi-graph-up::before) { content: "\f3f2"; }

:global(.bi-grid-1x2-fill::before) { content: "\f3f3"; }

:global(.bi-grid-1x2::before) { content: "\f3f4"; }

:global(.bi-grid-3x2-gap-fill::before) { content: "\f3f5"; }

:global(.bi-grid-3x2-gap::before) { content: "\f3f6"; }

:global(.bi-grid-3x2::before) { content: "\f3f7"; }

:global(.bi-grid-3x3-gap-fill::before) { content: "\f3f8"; }

:global(.bi-grid-3x3-gap::before) { content: "\f3f9"; }

:global(.bi-grid-3x3::before) { content: "\f3fa"; }

:global(.bi-grid-fill::before) { content: "\f3fb"; }

:global(.bi-grid::before) { content: "\f3fc"; }

:global(.bi-grip-horizontal::before) { content: "\f3fd"; }

:global(.bi-grip-vertical::before) { content: "\f3fe"; }

:global(.bi-hammer::before) { content: "\f3ff"; }

:global(.bi-hand-index-fill::before) { content: "\f400"; }

:global(.bi-hand-index-thumb-fill::before) { content: "\f401"; }

:global(.bi-hand-index-thumb::before) { content: "\f402"; }

:global(.bi-hand-index::before) { content: "\f403"; }

:global(.bi-hand-thumbs-down-fill::before) { content: "\f404"; }

:global(.bi-hand-thumbs-down::before) { content: "\f405"; }

:global(.bi-hand-thumbs-up-fill::before) { content: "\f406"; }

:global(.bi-hand-thumbs-up::before) { content: "\f407"; }

:global(.bi-handbag-fill::before) { content: "\f408"; }

:global(.bi-handbag::before) { content: "\f409"; }

:global(.bi-hash::before) { content: "\f40a"; }

:global(.bi-hdd-fill::before) { content: "\f40b"; }

:global(.bi-hdd-network-fill::before) { content: "\f40c"; }

:global(.bi-hdd-network::before) { content: "\f40d"; }

:global(.bi-hdd-rack-fill::before) { content: "\f40e"; }

:global(.bi-hdd-rack::before) { content: "\f40f"; }

:global(.bi-hdd-stack-fill::before) { content: "\f410"; }

:global(.bi-hdd-stack::before) { content: "\f411"; }

:global(.bi-hdd::before) { content: "\f412"; }

:global(.bi-headphones::before) { content: "\f413"; }

:global(.bi-headset::before) { content: "\f414"; }

:global(.bi-heart-fill::before) { content: "\f415"; }

:global(.bi-heart-half::before) { content: "\f416"; }

:global(.bi-heart::before) { content: "\f417"; }

:global(.bi-heptagon-fill::before) { content: "\f418"; }

:global(.bi-heptagon-half::before) { content: "\f419"; }

:global(.bi-heptagon::before) { content: "\f41a"; }

:global(.bi-hexagon-fill::before) { content: "\f41b"; }

:global(.bi-hexagon-half::before) { content: "\f41c"; }

:global(.bi-hexagon::before) { content: "\f41d"; }

:global(.bi-hourglass-bottom::before) { content: "\f41e"; }

:global(.bi-hourglass-split::before) { content: "\f41f"; }

:global(.bi-hourglass-top::before) { content: "\f420"; }

:global(.bi-hourglass::before) { content: "\f421"; }

:global(.bi-house-door-fill::before) { content: "\f422"; }

:global(.bi-house-door::before) { content: "\f423"; }

:global(.bi-house-fill::before) { content: "\f424"; }

:global(.bi-house::before) { content: "\f425"; }

:global(.bi-hr::before) { content: "\f426"; }

:global(.bi-hurricane::before) { content: "\f427"; }

:global(.bi-image-alt::before) { content: "\f428"; }

:global(.bi-image-fill::before) { content: "\f429"; }

:global(.bi-image::before) { content: "\f42a"; }

:global(.bi-images::before) { content: "\f42b"; }

:global(.bi-inbox-fill::before) { content: "\f42c"; }

:global(.bi-inbox::before) { content: "\f42d"; }

:global(.bi-inboxes-fill::before) { content: "\f42e"; }

:global(.bi-inboxes::before) { content: "\f42f"; }

:global(.bi-info-circle-fill::before) { content: "\f430"; }

:global(.bi-info-circle::before) { content: "\f431"; }

:global(.bi-info-square-fill::before) { content: "\f432"; }

:global(.bi-info-square::before) { content: "\f433"; }

:global(.bi-info::before) { content: "\f434"; }

:global(.bi-input-cursor-text::before) { content: "\f435"; }

:global(.bi-input-cursor::before) { content: "\f436"; }

:global(.bi-instagram::before) { content: "\f437"; }

:global(.bi-intersect::before) { content: "\f438"; }

:global(.bi-journal-album::before) { content: "\f439"; }

:global(.bi-journal-arrow-down::before) { content: "\f43a"; }

:global(.bi-journal-arrow-up::before) { content: "\f43b"; }

:global(.bi-journal-bookmark-fill::before) { content: "\f43c"; }

:global(.bi-journal-bookmark::before) { content: "\f43d"; }

:global(.bi-journal-check::before) { content: "\f43e"; }

:global(.bi-journal-code::before) { content: "\f43f"; }

:global(.bi-journal-medical::before) { content: "\f440"; }

:global(.bi-journal-minus::before) { content: "\f441"; }

:global(.bi-journal-plus::before) { content: "\f442"; }

:global(.bi-journal-richtext::before) { content: "\f443"; }

:global(.bi-journal-text::before) { content: "\f444"; }

:global(.bi-journal-x::before) { content: "\f445"; }

:global(.bi-journal::before) { content: "\f446"; }

:global(.bi-journals::before) { content: "\f447"; }

:global(.bi-joystick::before) { content: "\f448"; }

:global(.bi-justify-left::before) { content: "\f449"; }

:global(.bi-justify-right::before) { content: "\f44a"; }

:global(.bi-justify::before) { content: "\f44b"; }

:global(.bi-kanban-fill::before) { content: "\f44c"; }

:global(.bi-kanban::before) { content: "\f44d"; }

:global(.bi-key-fill::before) { content: "\f44e"; }

:global(.bi-key::before) { content: "\f44f"; }

:global(.bi-keyboard-fill::before) { content: "\f450"; }

:global(.bi-keyboard::before) { content: "\f451"; }

:global(.bi-ladder::before) { content: "\f452"; }

:global(.bi-lamp-fill::before) { content: "\f453"; }

:global(.bi-lamp::before) { content: "\f454"; }

:global(.bi-laptop-fill::before) { content: "\f455"; }

:global(.bi-laptop::before) { content: "\f456"; }

:global(.bi-layer-backward::before) { content: "\f457"; }

:global(.bi-layer-forward::before) { content: "\f458"; }

:global(.bi-layers-fill::before) { content: "\f459"; }

:global(.bi-layers-half::before) { content: "\f45a"; }

:global(.bi-layers::before) { content: "\f45b"; }

:global(.bi-layout-sidebar-inset-reverse::before) { content: "\f45c"; }

:global(.bi-layout-sidebar-inset::before) { content: "\f45d"; }

:global(.bi-layout-sidebar-reverse::before) { content: "\f45e"; }

:global(.bi-layout-sidebar::before) { content: "\f45f"; }

:global(.bi-layout-split::before) { content: "\f460"; }

:global(.bi-layout-text-sidebar-reverse::before) { content: "\f461"; }

:global(.bi-layout-text-sidebar::before) { content: "\f462"; }

:global(.bi-layout-text-window-reverse::before) { content: "\f463"; }

:global(.bi-layout-text-window::before) { content: "\f464"; }

:global(.bi-layout-three-columns::before) { content: "\f465"; }

:global(.bi-layout-wtf::before) { content: "\f466"; }

:global(.bi-life-preserver::before) { content: "\f467"; }

:global(.bi-lightbulb-fill::before) { content: "\f468"; }

:global(.bi-lightbulb-off-fill::before) { content: "\f469"; }

:global(.bi-lightbulb-off::before) { content: "\f46a"; }

:global(.bi-lightbulb::before) { content: "\f46b"; }

:global(.bi-lightning-charge-fill::before) { content: "\f46c"; }

:global(.bi-lightning-charge::before) { content: "\f46d"; }

:global(.bi-lightning-fill::before) { content: "\f46e"; }

:global(.bi-lightning::before) { content: "\f46f"; }

:global(.bi-link-45deg::before) { content: "\f470"; }

:global(.bi-link::before) { content: "\f471"; }

:global(.bi-linkedin::before) { content: "\f472"; }

:global(.bi-list-check::before) { content: "\f473"; }

:global(.bi-list-nested::before) { content: "\f474"; }

:global(.bi-list-ol::before) { content: "\f475"; }

:global(.bi-list-stars::before) { content: "\f476"; }

:global(.bi-list-task::before) { content: "\f477"; }

:global(.bi-list-ul::before) { content: "\f478"; }

:global(.bi-list::before) { content: "\f479"; }

:global(.bi-lock-fill::before) { content: "\f47a"; }

:global(.bi-lock::before) { content: "\f47b"; }

:global(.bi-mailbox::before) { content: "\f47c"; }

:global(.bi-mailbox2::before) { content: "\f47d"; }

:global(.bi-map-fill::before) { content: "\f47e"; }

:global(.bi-map::before) { content: "\f47f"; }

:global(.bi-markdown-fill::before) { content: "\f480"; }

:global(.bi-markdown::before) { content: "\f481"; }

:global(.bi-mask::before) { content: "\f482"; }

:global(.bi-megaphone-fill::before) { content: "\f483"; }

:global(.bi-megaphone::before) { content: "\f484"; }

:global(.bi-menu-app-fill::before) { content: "\f485"; }

:global(.bi-menu-app::before) { content: "\f486"; }

:global(.bi-menu-button-fill::before) { content: "\f487"; }

:global(.bi-menu-button-wide-fill::before) { content: "\f488"; }

:global(.bi-menu-button-wide::before) { content: "\f489"; }

:global(.bi-menu-button::before) { content: "\f48a"; }

:global(.bi-menu-down::before) { content: "\f48b"; }

:global(.bi-menu-up::before) { content: "\f48c"; }

:global(.bi-mic-fill::before) { content: "\f48d"; }

:global(.bi-mic-mute-fill::before) { content: "\f48e"; }

:global(.bi-mic-mute::before) { content: "\f48f"; }

:global(.bi-mic::before) { content: "\f490"; }

:global(.bi-minecart-loaded::before) { content: "\f491"; }

:global(.bi-minecart::before) { content: "\f492"; }

:global(.bi-moisture::before) { content: "\f493"; }

:global(.bi-moon-fill::before) { content: "\f494"; }

:global(.bi-moon-stars-fill::before) { content: "\f495"; }

:global(.bi-moon-stars::before) { content: "\f496"; }

:global(.bi-moon::before) { content: "\f497"; }

:global(.bi-mouse-fill::before) { content: "\f498"; }

:global(.bi-mouse::before) { content: "\f499"; }

:global(.bi-mouse2-fill::before) { content: "\f49a"; }

:global(.bi-mouse2::before) { content: "\f49b"; }

:global(.bi-mouse3-fill::before) { content: "\f49c"; }

:global(.bi-mouse3::before) { content: "\f49d"; }

:global(.bi-music-note-beamed::before) { content: "\f49e"; }

:global(.bi-music-note-list::before) { content: "\f49f"; }

:global(.bi-music-note::before) { content: "\f4a0"; }

:global(.bi-music-player-fill::before) { content: "\f4a1"; }

:global(.bi-music-player::before) { content: "\f4a2"; }

:global(.bi-newspaper::before) { content: "\f4a3"; }

:global(.bi-node-minus-fill::before) { content: "\f4a4"; }

:global(.bi-node-minus::before) { content: "\f4a5"; }

:global(.bi-node-plus-fill::before) { content: "\f4a6"; }

:global(.bi-node-plus::before) { content: "\f4a7"; }

:global(.bi-nut-fill::before) { content: "\f4a8"; }

:global(.bi-nut::before) { content: "\f4a9"; }

:global(.bi-octagon-fill::before) { content: "\f4aa"; }

:global(.bi-octagon-half::before) { content: "\f4ab"; }

:global(.bi-octagon::before) { content: "\f4ac"; }

:global(.bi-option::before) { content: "\f4ad"; }

:global(.bi-outlet::before) { content: "\f4ae"; }

:global(.bi-paint-bucket::before) { content: "\f4af"; }

:global(.bi-palette-fill::before) { content: "\f4b0"; }

:global(.bi-palette::before) { content: "\f4b1"; }

:global(.bi-palette2::before) { content: "\f4b2"; }

:global(.bi-paperclip::before) { content: "\f4b3"; }

:global(.bi-paragraph::before) { content: "\f4b4"; }

:global(.bi-patch-check-fill::before) { content: "\f4b5"; }

:global(.bi-patch-check::before) { content: "\f4b6"; }

:global(.bi-patch-exclamation-fill::before) { content: "\f4b7"; }

:global(.bi-patch-exclamation::before) { content: "\f4b8"; }

:global(.bi-patch-minus-fill::before) { content: "\f4b9"; }

:global(.bi-patch-minus::before) { content: "\f4ba"; }

:global(.bi-patch-plus-fill::before) { content: "\f4bb"; }

:global(.bi-patch-plus::before) { content: "\f4bc"; }

:global(.bi-patch-question-fill::before) { content: "\f4bd"; }

:global(.bi-patch-question::before) { content: "\f4be"; }

:global(.bi-pause-btn-fill::before) { content: "\f4bf"; }

:global(.bi-pause-btn::before) { content: "\f4c0"; }

:global(.bi-pause-circle-fill::before) { content: "\f4c1"; }

:global(.bi-pause-circle::before) { content: "\f4c2"; }

:global(.bi-pause-fill::before) { content: "\f4c3"; }

:global(.bi-pause::before) { content: "\f4c4"; }

:global(.bi-peace-fill::before) { content: "\f4c5"; }

:global(.bi-peace::before) { content: "\f4c6"; }

:global(.bi-pen-fill::before) { content: "\f4c7"; }

:global(.bi-pen::before) { content: "\f4c8"; }

:global(.bi-pencil-fill::before) { content: "\f4c9"; }

:global(.bi-pencil-square::before) { content: "\f4ca"; }

:global(.bi-pencil::before) { content: "\f4cb"; }

:global(.bi-pentagon-fill::before) { content: "\f4cc"; }

:global(.bi-pentagon-half::before) { content: "\f4cd"; }

:global(.bi-pentagon::before) { content: "\f4ce"; }

:global(.bi-people-fill::before) { content: "\f4cf"; }

:global(.bi-people::before) { content: "\f4d0"; }

:global(.bi-percent::before) { content: "\f4d1"; }

:global(.bi-person-badge-fill::before) { content: "\f4d2"; }

:global(.bi-person-badge::before) { content: "\f4d3"; }

:global(.bi-person-bounding-box::before) { content: "\f4d4"; }

:global(.bi-person-check-fill::before) { content: "\f4d5"; }

:global(.bi-person-check::before) { content: "\f4d6"; }

:global(.bi-person-circle::before) { content: "\f4d7"; }

:global(.bi-person-dash-fill::before) { content: "\f4d8"; }

:global(.bi-person-dash::before) { content: "\f4d9"; }

:global(.bi-person-fill::before) { content: "\f4da"; }

:global(.bi-person-lines-fill::before) { content: "\f4db"; }

:global(.bi-person-plus-fill::before) { content: "\f4dc"; }

:global(.bi-person-plus::before) { content: "\f4dd"; }

:global(.bi-person-square::before) { content: "\f4de"; }

:global(.bi-person-x-fill::before) { content: "\f4df"; }

:global(.bi-person-x::before) { content: "\f4e0"; }

:global(.bi-person::before) { content: "\f4e1"; }

:global(.bi-phone-fill::before) { content: "\f4e2"; }

:global(.bi-phone-landscape-fill::before) { content: "\f4e3"; }

:global(.bi-phone-landscape::before) { content: "\f4e4"; }

:global(.bi-phone-vibrate-fill::before) { content: "\f4e5"; }

:global(.bi-phone-vibrate::before) { content: "\f4e6"; }

:global(.bi-phone::before) { content: "\f4e7"; }

:global(.bi-pie-chart-fill::before) { content: "\f4e8"; }

:global(.bi-pie-chart::before) { content: "\f4e9"; }

:global(.bi-pin-angle-fill::before) { content: "\f4ea"; }

:global(.bi-pin-angle::before) { content: "\f4eb"; }

:global(.bi-pin-fill::before) { content: "\f4ec"; }

:global(.bi-pin::before) { content: "\f4ed"; }

:global(.bi-pip-fill::before) { content: "\f4ee"; }

:global(.bi-pip::before) { content: "\f4ef"; }

:global(.bi-play-btn-fill::before) { content: "\f4f0"; }

:global(.bi-play-btn::before) { content: "\f4f1"; }

:global(.bi-play-circle-fill::before) { content: "\f4f2"; }

:global(.bi-play-circle::before) { content: "\f4f3"; }

:global(.bi-play-fill::before) { content: "\f4f4"; }

:global(.bi-play::before) { content: "\f4f5"; }

:global(.bi-plug-fill::before) { content: "\f4f6"; }

:global(.bi-plug::before) { content: "\f4f7"; }

:global(.bi-plus-circle-dotted::before) { content: "\f4f8"; }

:global(.bi-plus-circle-fill::before) { content: "\f4f9"; }

:global(.bi-plus-circle::before) { content: "\f4fa"; }

:global(.bi-plus-square-dotted::before) { content: "\f4fb"; }

:global(.bi-plus-square-fill::before) { content: "\f4fc"; }

:global(.bi-plus-square::before) { content: "\f4fd"; }

:global(.bi-plus::before) { content: "\f4fe"; }

:global(.bi-power::before) { content: "\f4ff"; }

:global(.bi-printer-fill::before) { content: "\f500"; }

:global(.bi-printer::before) { content: "\f501"; }

:global(.bi-puzzle-fill::before) { content: "\f502"; }

:global(.bi-puzzle::before) { content: "\f503"; }

:global(.bi-question-circle-fill::before) { content: "\f504"; }

:global(.bi-question-circle::before) { content: "\f505"; }

:global(.bi-question-diamond-fill::before) { content: "\f506"; }

:global(.bi-question-diamond::before) { content: "\f507"; }

:global(.bi-question-octagon-fill::before) { content: "\f508"; }

:global(.bi-question-octagon::before) { content: "\f509"; }

:global(.bi-question-square-fill::before) { content: "\f50a"; }

:global(.bi-question-square::before) { content: "\f50b"; }

:global(.bi-question::before) { content: "\f50c"; }

:global(.bi-rainbow::before) { content: "\f50d"; }

:global(.bi-receipt-cutoff::before) { content: "\f50e"; }

:global(.bi-receipt::before) { content: "\f50f"; }

:global(.bi-reception-0::before) { content: "\f510"; }

:global(.bi-reception-1::before) { content: "\f511"; }

:global(.bi-reception-2::before) { content: "\f512"; }

:global(.bi-reception-3::before) { content: "\f513"; }

:global(.bi-reception-4::before) { content: "\f514"; }

:global(.bi-record-btn-fill::before) { content: "\f515"; }

:global(.bi-record-btn::before) { content: "\f516"; }

:global(.bi-record-circle-fill::before) { content: "\f517"; }

:global(.bi-record-circle::before) { content: "\f518"; }

:global(.bi-record-fill::before) { content: "\f519"; }

:global(.bi-record::before) { content: "\f51a"; }

:global(.bi-record2-fill::before) { content: "\f51b"; }

:global(.bi-record2::before) { content: "\f51c"; }

:global(.bi-reply-all-fill::before) { content: "\f51d"; }

:global(.bi-reply-all::before) { content: "\f51e"; }

:global(.bi-reply-fill::before) { content: "\f51f"; }

:global(.bi-reply::before) { content: "\f520"; }

:global(.bi-rss-fill::before) { content: "\f521"; }

:global(.bi-rss::before) { content: "\f522"; }

:global(.bi-rulers::before) { content: "\f523"; }

:global(.bi-save-fill::before) { content: "\f524"; }

:global(.bi-save::before) { content: "\f525"; }

:global(.bi-save2-fill::before) { content: "\f526"; }

:global(.bi-save2::before) { content: "\f527"; }

:global(.bi-scissors::before) { content: "\f528"; }

:global(.bi-screwdriver::before) { content: "\f529"; }

:global(.bi-search::before) { content: "\f52a"; }

:global(.bi-segmented-nav::before) { content: "\f52b"; }

:global(.bi-server::before) { content: "\f52c"; }

:global(.bi-share-fill::before) { content: "\f52d"; }

:global(.bi-share::before) { content: "\f52e"; }

:global(.bi-shield-check::before) { content: "\f52f"; }

:global(.bi-shield-exclamation::before) { content: "\f530"; }

:global(.bi-shield-fill-check::before) { content: "\f531"; }

:global(.bi-shield-fill-exclamation::before) { content: "\f532"; }

:global(.bi-shield-fill-minus::before) { content: "\f533"; }

:global(.bi-shield-fill-plus::before) { content: "\f534"; }

:global(.bi-shield-fill-x::before) { content: "\f535"; }

:global(.bi-shield-fill::before) { content: "\f536"; }

:global(.bi-shield-lock-fill::before) { content: "\f537"; }

:global(.bi-shield-lock::before) { content: "\f538"; }

:global(.bi-shield-minus::before) { content: "\f539"; }

:global(.bi-shield-plus::before) { content: "\f53a"; }

:global(.bi-shield-shaded::before) { content: "\f53b"; }

:global(.bi-shield-slash-fill::before) { content: "\f53c"; }

:global(.bi-shield-slash::before) { content: "\f53d"; }

:global(.bi-shield-x::before) { content: "\f53e"; }

:global(.bi-shield::before) { content: "\f53f"; }

:global(.bi-shift-fill::before) { content: "\f540"; }

:global(.bi-shift::before) { content: "\f541"; }

:global(.bi-shop-window::before) { content: "\f542"; }

:global(.bi-shop::before) { content: "\f543"; }

:global(.bi-shuffle::before) { content: "\f544"; }

:global(.bi-signpost-2-fill::before) { content: "\f545"; }

:global(.bi-signpost-2::before) { content: "\f546"; }

:global(.bi-signpost-fill::before) { content: "\f547"; }

:global(.bi-signpost-split-fill::before) { content: "\f548"; }

:global(.bi-signpost-split::before) { content: "\f549"; }

:global(.bi-signpost::before) { content: "\f54a"; }

:global(.bi-sim-fill::before) { content: "\f54b"; }

:global(.bi-sim::before) { content: "\f54c"; }

:global(.bi-skip-backward-btn-fill::before) { content: "\f54d"; }

:global(.bi-skip-backward-btn::before) { content: "\f54e"; }

:global(.bi-skip-backward-circle-fill::before) { content: "\f54f"; }

:global(.bi-skip-backward-circle::before) { content: "\f550"; }

:global(.bi-skip-backward-fill::before) { content: "\f551"; }

:global(.bi-skip-backward::before) { content: "\f552"; }

:global(.bi-skip-end-btn-fill::before) { content: "\f553"; }

:global(.bi-skip-end-btn::before) { content: "\f554"; }

:global(.bi-skip-end-circle-fill::before) { content: "\f555"; }

:global(.bi-skip-end-circle::before) { content: "\f556"; }

:global(.bi-skip-end-fill::before) { content: "\f557"; }

:global(.bi-skip-end::before) { content: "\f558"; }

:global(.bi-skip-forward-btn-fill::before) { content: "\f559"; }

:global(.bi-skip-forward-btn::before) { content: "\f55a"; }

:global(.bi-skip-forward-circle-fill::before) { content: "\f55b"; }

:global(.bi-skip-forward-circle::before) { content: "\f55c"; }

:global(.bi-skip-forward-fill::before) { content: "\f55d"; }

:global(.bi-skip-forward::before) { content: "\f55e"; }

:global(.bi-skip-start-btn-fill::before) { content: "\f55f"; }

:global(.bi-skip-start-btn::before) { content: "\f560"; }

:global(.bi-skip-start-circle-fill::before) { content: "\f561"; }

:global(.bi-skip-start-circle::before) { content: "\f562"; }

:global(.bi-skip-start-fill::before) { content: "\f563"; }

:global(.bi-skip-start::before) { content: "\f564"; }

:global(.bi-slack::before) { content: "\f565"; }

:global(.bi-slash-circle-fill::before) { content: "\f566"; }

:global(.bi-slash-circle::before) { content: "\f567"; }

:global(.bi-slash-square-fill::before) { content: "\f568"; }

:global(.bi-slash-square::before) { content: "\f569"; }

:global(.bi-slash::before) { content: "\f56a"; }

:global(.bi-sliders::before) { content: "\f56b"; }

:global(.bi-smartwatch::before) { content: "\f56c"; }

:global(.bi-snow::before) { content: "\f56d"; }

:global(.bi-snow2::before) { content: "\f56e"; }

:global(.bi-snow3::before) { content: "\f56f"; }

:global(.bi-sort-alpha-down-alt::before) { content: "\f570"; }

:global(.bi-sort-alpha-down::before) { content: "\f571"; }

:global(.bi-sort-alpha-up-alt::before) { content: "\f572"; }

:global(.bi-sort-alpha-up::before) { content: "\f573"; }

:global(.bi-sort-down-alt::before) { content: "\f574"; }

:global(.bi-sort-down::before) { content: "\f575"; }

:global(.bi-sort-numeric-down-alt::before) { content: "\f576"; }

:global(.bi-sort-numeric-down::before) { content: "\f577"; }

:global(.bi-sort-numeric-up-alt::before) { content: "\f578"; }

:global(.bi-sort-numeric-up::before) { content: "\f579"; }

:global(.bi-sort-up-alt::before) { content: "\f57a"; }

:global(.bi-sort-up::before) { content: "\f57b"; }

:global(.bi-soundwave::before) { content: "\f57c"; }

:global(.bi-speaker-fill::before) { content: "\f57d"; }

:global(.bi-speaker::before) { content: "\f57e"; }

:global(.bi-speedometer::before) { content: "\f57f"; }

:global(.bi-speedometer2::before) { content: "\f580"; }

:global(.bi-spellcheck::before) { content: "\f581"; }

:global(.bi-square-fill::before) { content: "\f582"; }

:global(.bi-square-half::before) { content: "\f583"; }

:global(.bi-square::before) { content: "\f584"; }

:global(.bi-stack::before) { content: "\f585"; }

:global(.bi-star-fill::before) { content: "\f586"; }

:global(.bi-star-half::before) { content: "\f587"; }

:global(.bi-star::before) { content: "\f588"; }

:global(.bi-stars::before) { content: "\f589"; }

:global(.bi-stickies-fill::before) { content: "\f58a"; }

:global(.bi-stickies::before) { content: "\f58b"; }

:global(.bi-sticky-fill::before) { content: "\f58c"; }

:global(.bi-sticky::before) { content: "\f58d"; }

:global(.bi-stop-btn-fill::before) { content: "\f58e"; }

:global(.bi-stop-btn::before) { content: "\f58f"; }

:global(.bi-stop-circle-fill::before) { content: "\f590"; }

:global(.bi-stop-circle::before) { content: "\f591"; }

:global(.bi-stop-fill::before) { content: "\f592"; }

:global(.bi-stop::before) { content: "\f593"; }

:global(.bi-stoplights-fill::before) { content: "\f594"; }

:global(.bi-stoplights::before) { content: "\f595"; }

:global(.bi-stopwatch-fill::before) { content: "\f596"; }

:global(.bi-stopwatch::before) { content: "\f597"; }

:global(.bi-subtract::before) { content: "\f598"; }

:global(.bi-suit-club-fill::before) { content: "\f599"; }

:global(.bi-suit-club::before) { content: "\f59a"; }

:global(.bi-suit-diamond-fill::before) { content: "\f59b"; }

:global(.bi-suit-diamond::before) { content: "\f59c"; }

:global(.bi-suit-heart-fill::before) { content: "\f59d"; }

:global(.bi-suit-heart::before) { content: "\f59e"; }

:global(.bi-suit-spade-fill::before) { content: "\f59f"; }

:global(.bi-suit-spade::before) { content: "\f5a0"; }

:global(.bi-sun-fill::before) { content: "\f5a1"; }

:global(.bi-sun::before) { content: "\f5a2"; }

:global(.bi-sunglasses::before) { content: "\f5a3"; }

:global(.bi-sunrise-fill::before) { content: "\f5a4"; }

:global(.bi-sunrise::before) { content: "\f5a5"; }

:global(.bi-sunset-fill::before) { content: "\f5a6"; }

:global(.bi-sunset::before) { content: "\f5a7"; }

:global(.bi-symmetry-horizontal::before) { content: "\f5a8"; }

:global(.bi-symmetry-vertical::before) { content: "\f5a9"; }

:global(.bi-table::before) { content: "\f5aa"; }

:global(.bi-tablet-fill::before) { content: "\f5ab"; }

:global(.bi-tablet-landscape-fill::before) { content: "\f5ac"; }

:global(.bi-tablet-landscape::before) { content: "\f5ad"; }

:global(.bi-tablet::before) { content: "\f5ae"; }

:global(.bi-tag-fill::before) { content: "\f5af"; }

:global(.bi-tag::before) { content: "\f5b0"; }

:global(.bi-tags-fill::before) { content: "\f5b1"; }

:global(.bi-tags::before) { content: "\f5b2"; }

:global(.bi-telegram::before) { content: "\f5b3"; }

:global(.bi-telephone-fill::before) { content: "\f5b4"; }

:global(.bi-telephone-forward-fill::before) { content: "\f5b5"; }

:global(.bi-telephone-forward::before) { content: "\f5b6"; }

:global(.bi-telephone-inbound-fill::before) { content: "\f5b7"; }

:global(.bi-telephone-inbound::before) { content: "\f5b8"; }

:global(.bi-telephone-minus-fill::before) { content: "\f5b9"; }

:global(.bi-telephone-minus::before) { content: "\f5ba"; }

:global(.bi-telephone-outbound-fill::before) { content: "\f5bb"; }

:global(.bi-telephone-outbound::before) { content: "\f5bc"; }

:global(.bi-telephone-plus-fill::before) { content: "\f5bd"; }

:global(.bi-telephone-plus::before) { content: "\f5be"; }

:global(.bi-telephone-x-fill::before) { content: "\f5bf"; }

:global(.bi-telephone-x::before) { content: "\f5c0"; }

:global(.bi-telephone::before) { content: "\f5c1"; }

:global(.bi-terminal-fill::before) { content: "\f5c2"; }

:global(.bi-terminal::before) { content: "\f5c3"; }

:global(.bi-text-center::before) { content: "\f5c4"; }

:global(.bi-text-indent-left::before) { content: "\f5c5"; }

:global(.bi-text-indent-right::before) { content: "\f5c6"; }

:global(.bi-text-left::before) { content: "\f5c7"; }

:global(.bi-text-paragraph::before) { content: "\f5c8"; }

:global(.bi-text-right::before) { content: "\f5c9"; }

:global(.bi-textarea-resize::before) { content: "\f5ca"; }

:global(.bi-textarea-t::before) { content: "\f5cb"; }

:global(.bi-textarea::before) { content: "\f5cc"; }

:global(.bi-thermometer-half::before) { content: "\f5cd"; }

:global(.bi-thermometer-high::before) { content: "\f5ce"; }

:global(.bi-thermometer-low::before) { content: "\f5cf"; }

:global(.bi-thermometer-snow::before) { content: "\f5d0"; }

:global(.bi-thermometer-sun::before) { content: "\f5d1"; }

:global(.bi-thermometer::before) { content: "\f5d2"; }

:global(.bi-three-dots-vertical::before) { content: "\f5d3"; }

:global(.bi-three-dots::before) { content: "\f5d4"; }

:global(.bi-toggle-off::before) { content: "\f5d5"; }

:global(.bi-toggle-on::before) { content: "\f5d6"; }

:global(.bi-toggle2-off::before) { content: "\f5d7"; }

:global(.bi-toggle2-on::before) { content: "\f5d8"; }

:global(.bi-toggles::before) { content: "\f5d9"; }

:global(.bi-toggles2::before) { content: "\f5da"; }

:global(.bi-tools::before) { content: "\f5db"; }

:global(.bi-tornado::before) { content: "\f5dc"; }

:global(.bi-trash-fill::before) { content: "\f5dd"; }

:global(.bi-trash::before) { content: "\f5de"; }

:global(.bi-trash2-fill::before) { content: "\f5df"; }

:global(.bi-trash2::before) { content: "\f5e0"; }

:global(.bi-tree-fill::before) { content: "\f5e1"; }

:global(.bi-tree::before) { content: "\f5e2"; }

:global(.bi-triangle-fill::before) { content: "\f5e3"; }

:global(.bi-triangle-half::before) { content: "\f5e4"; }

:global(.bi-triangle::before) { content: "\f5e5"; }

:global(.bi-trophy-fill::before) { content: "\f5e6"; }

:global(.bi-trophy::before) { content: "\f5e7"; }

:global(.bi-tropical-storm::before) { content: "\f5e8"; }

:global(.bi-truck-flatbed::before) { content: "\f5e9"; }

:global(.bi-truck::before) { content: "\f5ea"; }

:global(.bi-tsunami::before) { content: "\f5eb"; }

:global(.bi-tv-fill::before) { content: "\f5ec"; }

:global(.bi-tv::before) { content: "\f5ed"; }

:global(.bi-twitch::before) { content: "\f5ee"; }

:global(.bi-twitter::before) { content: "\f5ef"; }

:global(.bi-type-bold::before) { content: "\f5f0"; }

:global(.bi-type-h1::before) { content: "\f5f1"; }

:global(.bi-type-h2::before) { content: "\f5f2"; }

:global(.bi-type-h3::before) { content: "\f5f3"; }

:global(.bi-type-italic::before) { content: "\f5f4"; }

:global(.bi-type-strikethrough::before) { content: "\f5f5"; }

:global(.bi-type-underline::before) { content: "\f5f6"; }

:global(.bi-type::before) { content: "\f5f7"; }

:global(.bi-ui-checks-grid::before) { content: "\f5f8"; }

:global(.bi-ui-checks::before) { content: "\f5f9"; }

:global(.bi-ui-radios-grid::before) { content: "\f5fa"; }

:global(.bi-ui-radios::before) { content: "\f5fb"; }

:global(.bi-umbrella-fill::before) { content: "\f5fc"; }

:global(.bi-umbrella::before) { content: "\f5fd"; }

:global(.bi-union::before) { content: "\f5fe"; }

:global(.bi-unlock-fill::before) { content: "\f5ff"; }

:global(.bi-unlock::before) { content: "\f600"; }

:global(.bi-upc-scan::before) { content: "\f601"; }

:global(.bi-upc::before) { content: "\f602"; }

:global(.bi-upload::before) { content: "\f603"; }

:global(.bi-vector-pen::before) { content: "\f604"; }

:global(.bi-view-list::before) { content: "\f605"; }

:global(.bi-view-stacked::before) { content: "\f606"; }

:global(.bi-vinyl-fill::before) { content: "\f607"; }

:global(.bi-vinyl::before) { content: "\f608"; }

:global(.bi-voicemail::before) { content: "\f609"; }

:global(.bi-volume-down-fill::before) { content: "\f60a"; }

:global(.bi-volume-down::before) { content: "\f60b"; }

:global(.bi-volume-mute-fill::before) { content: "\f60c"; }

:global(.bi-volume-mute::before) { content: "\f60d"; }

:global(.bi-volume-off-fill::before) { content: "\f60e"; }

:global(.bi-volume-off::before) { content: "\f60f"; }

:global(.bi-volume-up-fill::before) { content: "\f610"; }

:global(.bi-volume-up::before) { content: "\f611"; }

:global(.bi-vr::before) { content: "\f612"; }

:global(.bi-wallet-fill::before) { content: "\f613"; }

:global(.bi-wallet::before) { content: "\f614"; }

:global(.bi-wallet2::before) { content: "\f615"; }

:global(.bi-watch::before) { content: "\f616"; }

:global(.bi-water::before) { content: "\f617"; }

:global(.bi-whatsapp::before) { content: "\f618"; }

:global(.bi-wifi-1::before) { content: "\f619"; }

:global(.bi-wifi-2::before) { content: "\f61a"; }

:global(.bi-wifi-off::before) { content: "\f61b"; }

:global(.bi-wifi::before) { content: "\f61c"; }

:global(.bi-wind::before) { content: "\f61d"; }

:global(.bi-window-dock::before) { content: "\f61e"; }

:global(.bi-window-sidebar::before) { content: "\f61f"; }

:global(.bi-window::before) { content: "\f620"; }

:global(.bi-wrench::before) { content: "\f621"; }

:global(.bi-x-circle-fill::before) { content: "\f622"; }

:global(.bi-x-circle::before) { content: "\f623"; }

:global(.bi-x-diamond-fill::before) { content: "\f624"; }

:global(.bi-x-diamond::before) { content: "\f625"; }

:global(.bi-x-octagon-fill::before) { content: "\f626"; }

:global(.bi-x-octagon::before) { content: "\f627"; }

:global(.bi-x-square-fill::before) { content: "\f628"; }

:global(.bi-x-square::before) { content: "\f629"; }

:global(.bi-x::before) { content: "\f62a"; }

:global(.bi-youtube::before) { content: "\f62b"; }

:global(.bi-zoom-in::before) { content: "\f62c"; }

:global(.bi-zoom-out::before) { content: "\f62d"; }
</style>

<Router {routes} />
