<script>
  import { metatags } from "@roxi/routify";
  metatags.title = "Routify Prismjs Example";
  import { onMount } from "svelte";
  let Prism;
  let code = "let b = 4;";
  //alert("here");
  onMount(async () => {
    //alert("here here");
    const prismModule = await import("svelte-prismjs");
    await import("prismjs/components/prism-c.js");
    await import("prismjs/components/prism-json.js");
    //await import("prism-svelte");
    //await import("prismjs/plugins/line-highlight/prism-line-highlight.js");
    //await import("prismjs/plugins/file-highlight/prism-file-highlight.js");
    //alert("here here");
    // Once everything is loaded load the prismjs module
    Prism = prismModule.default;
    /*setInterval(() => {
      code = code == "let b = 4;" ? "let c = 323;" : "let b = 4;";
      console.log("should notice change");
  }, 3000);*/ // I don't need this, kefah.
  });
</script>

<h1>Examples</h1>

<p>This shows some simple examples of how to use sveltejs-prism plugin.</p>

<svelte:component this="{Prism}">
  {JSON.stringify({ hello: "World" }, undefined, 1)}
</svelte:component>

<h2>Javascript Language Simple</h2>

<svelte:component this="{Prism}">
  {`let b = 3;
function helloworld() {
	console.log("Hello World");
}
`}
</svelte:component>

<h2>Javascript With Line Numbers</h2>

<svelte:component this="{Prism}" showLineNumbers="{true}">
  {`
	let b = 3;
function helloworld() {
	console.log("Hello World");
}
`}
</svelte:component>

<h2>C language with line numbers no white space cleanup</h2>

<svelte:component
  this="{Prism}"
  language="c"
  normalizeWhiteSpace="{false}"
  code="{`
	
	int b =3;
int c = 32;
`}" />

<h2>C language with line numbers with white space cleanup</h2>

<svelte:component
  this="{Prism}"
  showLineNumbers="{true}"
  language="c"
  code="{`
	
	int b =3;
int c = 32;
`}" />

<h2>Example showing code can change every 3 seconds</h2>
<svelte:component this="{Prism}" showLineNumbers="{true}" code="{code}" />

<h2>Example using prism plugin not supported out of the box.</h2>

<p>
  We are using the
  <a href="https://prismjs.com/plugins/line-highlight/"> line highlight plugin </a>
  plugin which requires the data-line attribute to be passed in. Other plugins just require you to include the js after the
  component is imported
</p>
<svelte:component this="{Prism}" data-line="3,5-8">
  {`
onMount(() => {
	if (normalizeWhiteSpace) {
		Prism.plugins.NormalizeWhitespace.setDefaults(normalizeWhiteSpaceConfig);
	}
});
afterUpdate(async () => {
	// code variable if they are using a prop
	if (fakeCodeEl && fakeCodeEl.innerHTML !== "") {
		code = fakeCodeEl.innerHTML.trim();
	}
	// We need to wait till everything been rendered before we can
	// call highlightAll and load all the plugins
	await tick();
	// This will make sure all the plugins are loaded
	// Prism.highlight will not do that
	Prism.highlightAll();
});
`}
</svelte:component>

<style global>
  /*@import "highlight.js/styles/a11y-light.css";*/
  :global(pre[class*="language-"].line-numbers) {
	position: relative;
	padding-left: 3.8em;
	counter-reset: linenumber;
}
  :global(pre[class*="language-"].line-numbers) > :global(code) {
	position: relative;
	white-space: inherit;
}
  :global(.line-numbers) :global(.line-numbers-rows) {
	position: absolute;
	pointer-events: none;
	top: 0;
	font-size: 100%;
	left: -3.8em;
	width: 3em; /* works for line-numbers below 1000 lines */
	letter-spacing: -1px;
	border-right: 1px solid #999;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

}
  :global(.line-numbers-rows) > :global(span) {
		display: block;
		counter-increment: linenumber;
	}
  :global(.line-numbers-rows) > :global(span:before) {
			content: counter(linenumber);
			color: #999;
			display: block;
			padding-right: 0.8em;
			text-align: right;
		}
  :global(.command-line-prompt) {
	border-right: 1px solid #999;
	display: block;
	float: left;
	font-size: 100%;
	letter-spacing: -1px;
	margin-right: 1em;
	pointer-events: none;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
  :global(.command-line-prompt) > :global(span:before) {
	color: #999;
	content: ' ';
	display: block;
	padding-right: 0.8em;
}
  :global(.command-line-prompt) > :global(span[data-user]:before) {
	content: "[" attr(data-user) "@" attr(data-host) "] $";
}
  :global(.command-line-prompt) > :global(span[data-user="root"]:before) {
	content: "[" attr(data-user) "@" attr(data-host) "] #";
}
  :global(.command-line-prompt) > :global(span[data-prompt]:before) {
	content: attr(data-prompt);
}
  :global(pre[data-line]) {
	position: relative;
	padding: 1em 0 1em 3em;
}
  :global(.line-highlight) {
	position: absolute;
	left: 0;
	right: 0;
	padding: inherit 0;
	margin-top: 1em; /* Same as .prism’s padding-top */

	background: hsla(24, 20%, 50%,.08);
	background: linear-gradient(to right, hsla(24, 20%, 50%,.1) 70%, hsla(24, 20%, 50%,0));

	pointer-events: none;

	line-height: inherit;
	white-space: pre;
}
  @media print {
	:global(.line-highlight) {
		/*
		 * This will prevent browsers from replacing the background color with white.
		 * It's necessary because the element is layered on top of the displayed code.
		 */
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
	}
}
  :global(.line-highlight:before),
	:global(.line-highlight[data-end]:after) {
		content: attr(data-start);
		position: absolute;
		top: .4em;
		left: .6em;
		min-width: 1em;
		padding: 0 .5em;
		background-color: hsla(24, 20%, 50%,.4);
		color: hsl(24, 20%, 95%);
		font: bold 65%/1.5 sans-serif;
		text-align: center;
		vertical-align: .3em;
		border-radius: 999px;
		text-shadow: none;
		box-shadow: 0 1px white;
	}
  :global(.line-highlight[data-end]:after) {
		content: attr(data-end);
		top: auto;
		bottom: .4em;
	}
  :global(.line-numbers) :global(.line-highlight:before),
:global(.line-numbers) :global(.line-highlight:after) {
	content: none;
}
  :global(pre[id].linkable-line-numbers) :global(span.line-numbers-rows) {
	pointer-events: all;
}
  :global(pre[id].linkable-line-numbers) :global(span.line-numbers-rows) > :global(span:before) {
	cursor: pointer;
}
  :global(pre[id].linkable-line-numbers) :global(span.line-numbers-rows) > :global(span:hover:before) {
	background-color: rgba(128, 128, 128, .2);
}
  /**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
  :global(code[class*="language-"]),
:global(pre[class*="language-"]) {
	color: black;
	background: none;
	text-shadow: 0 1px white;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
  :global(pre[class*="language-"]::-moz-selection), :global(pre[class*="language-"]) :global(::-moz-selection), :global(code[class*="language-"]::-moz-selection), :global(code[class*="language-"]) :global(::-moz-selection) {
	text-shadow: none;
	background: #b3d4fc;
}
  :global(pre[class*="language-"]::selection), :global(pre[class*="language-"]) :global(::selection), :global(code[class*="language-"]::selection), :global(code[class*="language-"]) :global(::selection) {
	text-shadow: none;
	background: #b3d4fc;
}
  @media print {
	:global(code[class*="language-"]),
	:global(pre[class*="language-"]) {
		text-shadow: none;
	}
}
  /* Code blocks */
  :global(pre[class*="language-"]) {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
}
  :global(:not(pre)) > :global(code[class*="language-"]),
:global(pre[class*="language-"]) {
	background: #f5f2f0;
}
  /* Inline code */
  :global(:not(pre)) > :global(code[class*="language-"]) {
	padding: .1em;
	border-radius: .3em;
	white-space: normal;
}
  :global(.token.comment),
:global(.token.prolog),
:global(.token.doctype),
:global(.token.cdata) {
	color: slategray;
}
  :global(.token.punctuation) {
	color: #999;
}
  :global(.token.namespace) {
	opacity: .7;
}
  :global(.token.property),
:global(.token.tag),
:global(.token.boolean),
:global(.token.number),
:global(.token.constant),
:global(.token.symbol),
:global(.token.deleted) {
	color: #905;
}
  :global(.token.selector),
:global(.token.attr-name),
:global(.token.string),
:global(.token.char),
:global(.token.builtin),
:global(.token.inserted) {
	color: #690;
}
  :global(.token.operator),
:global(.token.entity),
:global(.token.url),
:global(.language-css) :global(.token.string),
:global(.style) :global(.token.string) {
	color: #9a6e3a;
	/* This background color was intended by the author of this theme. */
	background: hsla(0, 0%, 100%, .5);
}
  :global(.token.atrule),
:global(.token.attr-value),
:global(.token.keyword) {
	color: #07a;
}
  :global(.token.function),
:global(.token.class-name) {
	color: #DD4A68;
}
  :global(.token.regex),
:global(.token.important),
:global(.token.variable) {
	color: #e90;
}
  :global(.token.important),
:global(.token.bold) {
	font-weight: bold;
}
  :global(.token.italic) {
	font-style: italic;
}
  :global(.token.entity) {
	cursor: help;
}
  /**
 * prism.js Coy theme for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/tshedor/workshop-wp-theme (Example: http://workshop.kansan.com/category/sessions/basics or http://workshop.timshedor.com/category/sessions/basics);
 * @author Tim  Shedor
 */
  :global(code[class*="language-"]),
:global(pre[class*="language-"]) {
	color: black;
	background: none;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
  /* Code blocks */
  :global(pre[class*="language-"]) {
	position: relative;
	margin: .5em 0;
	overflow: visible;
	padding: 0;
}
  :global(pre[class*="language-"])>:global(code) {
	position: relative;
	border-left: 10px solid #358ccb;
	box-shadow: -1px 0px 0px 0px #358ccb, 0px 0px 0px 1px #dfdfdf;
	background-color: #fdfdfd;
	background-image: linear-gradient(transparent 50%, rgba(69, 142, 209, 0.04) 50%);
	background-size: 3em 3em;
	background-origin: content-box;
	background-attachment: local;
}
  :global(code[class*="language-"]) {
	max-height: inherit;
	height: inherit;
	padding: 0 1em;
	display: block;
	overflow: auto;
}
  /* Margin bottom to accommodate shadow */
  :global(:not(pre)) > :global(code[class*="language-"]),
:global(pre[class*="language-"]) {
	background-color: #fdfdfd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin-bottom: 1em;
}
  /* Inline code */
  :global(:not(pre)) > :global(code[class*="language-"]) {
	position: relative;
	padding: .2em;
	border-radius: 0.3em;
	color: #c92c2c;
	border: 1px solid rgba(0, 0, 0, 0.1);
	display: inline;
	white-space: normal;
}
  :global(pre[class*="language-"]:before),
:global(pre[class*="language-"]:after) {
	content: '';
	z-index: -2;
	display: block;
	position: absolute;
	bottom: 0.75em;
	left: 0.18em;
	width: 40%;
	height: 20%;
	max-height: 13em;
	box-shadow: 0px 13px 8px #979797;
	-webkit-transform: rotate(-2deg);
	-moz-transform: rotate(-2deg);
	-ms-transform: rotate(-2deg);
	-o-transform: rotate(-2deg);
	transform: rotate(-2deg);
}
  :global(pre[class*="language-"]:after) {
	right: 0.75em;
	left: auto;
	-webkit-transform: rotate(2deg);
	-moz-transform: rotate(2deg);
	-ms-transform: rotate(2deg);
	-o-transform: rotate(2deg);
	transform: rotate(2deg);
}
  :global(.token.comment),
:global(.token.block-comment),
:global(.token.prolog),
:global(.token.doctype),
:global(.token.cdata) {
	color: #7D8B99;
}
  :global(.token.punctuation) {
	color: #5F6364;
}
  :global(.token.property),
:global(.token.tag),
:global(.token.boolean),
:global(.token.number),
:global(.token.function-name),
:global(.token.constant),
:global(.token.symbol),
:global(.token.deleted) {
	color: #c92c2c;
}
  :global(.token.selector),
:global(.token.attr-name),
:global(.token.string),
:global(.token.char),
:global(.token.function),
:global(.token.builtin),
:global(.token.inserted) {
	color: #2f9c0a;
}
  :global(.token.operator),
:global(.token.entity),
:global(.token.url),
:global(.token.variable) {
	color: #a67f59;
	background: rgba(255, 255, 255, 0.5);
}
  :global(.token.atrule),
:global(.token.attr-value),
:global(.token.keyword),
:global(.token.class-name) {
	color: #1990b8;
}
  :global(.token.regex),
:global(.token.important) {
	color: #e90;
}
  :global(.language-css) :global(.token.string),
:global(.style) :global(.token.string) {
	color: #a67f59;
	background: rgba(255, 255, 255, 0.5);
}
  :global(.token.important) {
	font-weight: normal;
}
  :global(.token.bold) {
	font-weight: bold;
}
  :global(.token.italic) {
	font-style: italic;
}
  :global(.token.entity) {
	cursor: help;
}
  :global(.token.namespace) {
	opacity: .7;
}
  @media screen and (max-width: 767px) {
	:global(pre[class*="language-"]:before),
	:global(pre[class*="language-"]:after) {
		bottom: 14px;
		box-shadow: none;
	}

}
  /* Plugin styles: Line Numbers */
  :global(pre[class*="language-"].line-numbers.line-numbers) {
	padding-left: 0;
}
  :global(pre[class*="language-"].line-numbers.line-numbers) :global(code) {
	padding-left: 3.8em;
}
  :global(pre[class*="language-"].line-numbers.line-numbers) :global(.line-numbers-rows) {
	left: 0;
}
  /* Plugin styles: Line Highlight */
  :global(pre[class*="language-"][data-line]) {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
}
  :global(pre[data-line]) :global(code) {
	position: relative;
	padding-left: 4em;
}
  :global(pre) :global(.line-highlight) {
	margin-top: 0;
}
</style>
